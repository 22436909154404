import { axios } from "@/utils/request";


export function getType(data) {
    return axios({
        url: '/pc/game/category',
        method: 'get',
        data
    })
}
export function getProj(data) {
    return axios({
        url: '/pc/game/project',
        method: 'get',
        data
    })
}
// 获取所有赛事
export function getCompet(data) {
    return axios({
        url: '/pc/game/game',
        method: 'post',
        data
    })
}
// 获取轮播图
export function getSwiper(data) {
    return axios({
        url: '/pc/carousel',
        method: 'get',
        data
    })
}
// 获取团队信息
export function getTeam(data) {
    return axios({
        url: '/pc/team/team',
        method: 'get',
        data
    })
}
// 提交团队信息
export function createTeam(data) {
    return axios({
        url: '/pc/team/create',
        method: 'post',
        data
    })
}
//获取国家列表
export function getcountry(data) {
    return axios({
        url: '/pc/country',
        method: 'get',
        data
    })
}
//获取民族列表
export function getnational(data) {
    return axios({
        url: '/pc/national/103',
        method: 'get',
        data
    })
}
// 添加管理人员
export function addleader(data) {
    return axios({
        url: '/pc/team/addManage/1',
        method: 'post',
        data
    })
}
// 获取管理人员
export function getleader(data) {
    return axios({
        url: '/pc/team/teamManage/1',
        method: 'get',
        data
    })
}
// 获取管理人员
export function getleaderlist(data) {
    return axios({
        url: '/pc/team/teamManageList',
        method: 'get',
        data
    })
}
// 获取上传模板
export function uploadmb(data) {
    return axios({
        url: '/pc/team/importSport',
        method: 'post',
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
        data
    })
}
// 获取运动员列表
export function getplayerlist(data) {
    return axios({
        url: '/pc/team/teamSportList',
        method: 'get',
        data
    })
}
// 保存批量运动员
export function saveplayer(data) {
    return axios({
        url: '/pc/team/batchSport',
        method: 'post',
        data
    })
}
// 获取银行信息
export function getbank(data) {
    return axios({
        url: '/pc/paymethod',
        method: 'get',
        data
    })
}

//获取省市区
export function getarea(data) {
    return axios({
        url: '/api/area',
        method: 'post',
        data
    })
}
