<template>
    <div id="applylist">
        <my-header></my-header>
        <div class="team">
            <div class="main-title">
                <p>报名人员</p>
                <button class="title-btn" @click="addPlayer">添加人员</button>
            </div>
            <div class="team-msg">
                <p>已报名{{total}}人</p>
                <el-table :data="playerList" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}" :span-method='join' border :height="600">
                    <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
                    <el-table-column prop="z_index" label="序号" align="center" width="50"></el-table-column>
                    <el-table-column prop="sport" label="运动员" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.sport.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.group.rule_type=='personal'">{{scope.row.group.name}},{{scope.row.groupChild.name}},{{scope.row.groupLevel.level_name}}</span>
                            <span v-else>{{scope.row.group.name}},{{scope.row.groupChild.name}},{{scope.row.groupLevel.level_name}}{{scope.row.group_index}}组</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="btn">
                <button class="btn-up" @click="lastStep">上一步</button>
                <button class="btn-down" @click="netxStep">下一步</button>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from "@/utils/request";
import header from '@/components/header.vue'
export default {
    name: "applylist",
    data() {
        return {
            playerList: [],
            countryList: [{ value: 1, lable: '中国' }],
            nationList: [{ value: 1, lable: '汉族' }],
            cardList: [{ value: 0, lable: '身份证' }],
            dialogFormVisible: false,
            labelPosition: 'right',
            currentPage: 1,
            total: 0,
            match_id: '',
            saveList: [],
            sign: [],
            type: '',
        };
    },
    created() {
        const { id } = this.$route.query
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        }
        this.match_id = id
        this.getList()
    },
    components: {
        'my-header': header
    },
    methods: {
        addPlayer() {
            this.$router.push({
                name: 'playerapply',
                query: {
                    id: this.match_id,

                    type: this.type
                }
            })
        },
        getList() {
            axios.get('/pc/team/saveData/' + this.match_id).then(res => {
                this.playerList = res.data
                let a = 1;
                for (var p = 0; p < this.playerList.length; p++) {
                    if (this.playerList[p].count == 1) {
                        this.playerList[p].z_index = a
                        a += 1
                    } else {
                        this.playerList[p].z_index = a
                        a += 1
                        p += this.playerList[p].count - 1
                    }
                    this.total = a - 1
                }
            })
        },
        join({ row, column, rowIndex, columnIndex }) {
            if (columnIndex !== 2) {
                if (row.count > 1) {
                    if (rowIndex == 0) {
                        return {
                            rowspan: row.count,
                            colspan: 1
                        };
                    } else {
                        if (row.sport.name != this.playerList[rowIndex - 1].sport.name) {
                            return {
                                rowspan: row.count,
                                colspan: 1
                            };
                        } else {
                            return {
                                rowspan: 0,
                                colspan: 0
                            };
                        }
                    }

                }
            }
        },
        netxStep() {
            axios.get('/pc/team/checkOk/' + this.match_id).then(res => {
                if (res.status == 200) {
                    if (this.type == '') {
                        this.$router.push({
                            path: '/paylist',
                            query: {
                                id: this.match_id
                            }
                        })
                    } else {
                        this.$router.push({
                            path: '/paylist',
                            query: {
                                id: this.match_id,
                                type: this.type
                            }
                        })
                    }

                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
            })

        },
        lastStep() {
            this.$router.push({
                path: '/playerapply',
                query: {
                    id: this.match_id,
                    type: this.type
                }
            })
        },
        update() {
            this.playerList.push(this.form);
            this.dialogFormVisible = false;
        },
        handleEdit(index, row) {
            console.log(row)
            this.$router.push({
                path: 'playerapply',
                query: {
                    id: this.match_id,
                    sport: row.sport.id,
                    type: this.type,
                    name: row.sport.name,
                    sex: row.sport.sex
                }
            })
        },
        handleDelete(index, row) {
            // 设置类似于console类型的功能
            this.$confirm("是否删除该条记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    axios.delete('/pc/team/delSaveData/' + row.sport_id + '/' + this.match_id).then(res => {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        cancel() {
            // 取消的时候直接设置对话框不可见即可
            this.dialogFormVisible = false;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    }
};
</script>

<style scoped>
#applylist {
    height: 1080px;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.team {
    width: 90%;
    height: 914px;
    background-color: white;
    /* margin-left: 360px; */
    margin: 0 auto;
    margin-top: 45px;
    padding-bottom: 30px;
    border-radius: 15px;
    position: relative;
}
.main-title {
    height: 80px;
    width: 100%;
    border-bottom: 2px solid #f1f1f1;
    /* background-color: aqua; */
    display: flex;
    position: relative;
}
.main-title p {
    line-height: 80px;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    margin-left: 61px;
}
.main-title button {
    width: 121px;
    height: 36px;
    background-color: #ff8f3a;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    margin-top: 25px;
    position: absolute;
    right: 66px;
}
.team-msg {
    width: 1139px;
    height: 530px;
    margin: 45px 0 0 31px;
    border-radius: 5px;
}
.team-msg p {
    text-align: left;
    font-weight: bold;
    color: #000000;
    font-size: 18px;
    margin-bottom: 28px;
}
.team-btn {
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 36px;
    float: right;
    margin: 41px 33px 0 0;
}
.btn1 {
    text-align: left;
}
.btn1 button {
    width: 121px;
    height: 36px;
    background-color: transparent;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    margin-top: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #ff8f3a;
}
.pagetab {
    margin-top: 20px;
    float: right;
    margin-right: 30px;
}
.btn {
    height: 36px;
    text-align: right;
    position: absolute;
    bottom: 53px;
    right: 0;
}
.btn button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
    margin-right: 64px;
}
</style>

