import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'
import login from '../views/login/login'
import detail from '../views/competiton/detail'
import haveteam from '../views/team/haveteam'
import noteam from '../views/team/noteam'
import noleader from '../views/leader/noleader'
import haveleader from '../views/leader/haveleader'
import addplayer from '../views/players/addplayers'
import playerlist from '../views/players/playerslist'
import playerapply from '../views/players/playersapply'
import applylist from '../views/players/applylist'
import paylist from '../views/pay/paylist'
import paydetail from '../views/pay/paydetail'
import user from '../views/user'
import team from '../components/teampage'
import personal from '../components/personalpage'
import apply from '../components/applypage'
import order from '../components/orderpage'
import set from '../components/setpage'
import applydetail from '../components/applydetail'
import demo from '../views/demo'
import rule from '../views/rule'


Vue.use(VueRouter)
// 路由守卫·
// router.beforeEach((to,from,next) =>{
//   	if(to.path === '/login') return next()
//   	const tokenStr = sessionStorage.getItem('token')
//   	if(!tokenStr) return next('/login')
//   	next()
// })
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [
		// {
		//   path: '/',
		//   name: 'home',
		//   component: HomeView
		// },
		{
			path: '/demo',
			name: 'demo',
			component: demo
		},
		{
			path: '/',
			name: 'index',
			component: index
		},
		{
			path: '/login',
			name: 'login',
			component: login
		},
		{
			path: '/detail',
			name: 'detail',
			component: detail
		},
		{
			path: '/haveteam',
			name: 'haveteam',
			component: haveteam
		},
		{
			path: '/noteam',
			name: 'noteam',
			component: noteam
		},
		{
			path: '/noleader',
			name: 'noleader',
			component: noleader
		},
		{
			path: '/haveleader',
			name: 'haveleader',
			component: haveleader
		},
		{
			path: '/addplayer',
			name: 'addplayer',
			component: addplayer
		},
		{
			path: '/playerlist',
			name: 'playerlist',
			component: playerlist
		},
		{
			path: '/playerapply',
			name: 'playerapply',
			component: playerapply
		},
		{
			path: '/applylist',
			name: 'applylist',
			component: applylist
		},
		{
			path: '/paylist',
			name: 'paylist',
			component: paylist
		},
		{
			path: '/paydetail',
			name: 'paydetail',
			component: paydetail
		},
		{
			path: '/rule',
			name: 'rule',
			component: rule
		},
		{
			path: '/user',
			name: 'user',
			component: user,
			children: [
				{
					path: '',
					name: 'team',
					component: team,
				},
				{
					path: '/user/personal',
					name: 'personal',
					component: personal,
				},
				{
					path: '/user/apply',
					name: 'apply',
					component: apply,
				},
				{
					path: '/user/order',
					name: 'order',
					component: order,
				},
				{
					path: '/user/set',
					name: 'set',
					component: set,
				},
				{
					path: '/user/applydetail',
					name: 'applydetail',
					component: applydetail,
				}
			]
		},


	]
})

// router.beforeEach((to, from, next) => {
// 	document.body.scrollTop = 0
// 	if (to.path === '/login') {
// 		next();
// 	} else {
// 		let token = localStorage.getItem('Authorization');
// 		if (token === null || token === '') {
// 			next('/login');
// 		} else {
// 			next()
// 		}
// 	}
// })

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
})


export default router
