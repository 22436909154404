<template>
    <div id="noteam" :style="{height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="getform">
            <div class="form-left">
                <el-upload class="avatar-uploader" :action=url :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="left-tip">
                    <p>请上传彩色照片</p>
                    <p>文件格式:JPG,JPEG,PNG</p>
                    <p>诈照片大小不能超过3M</p>
                </div>
            </div>
            <div class="form-right">
                <el-form :inline="true" :model="teamMsg" class="demo-form-inline" label-position="right" :rules="rules" ref="teamMsg">
                    <el-row style="height:42px">
                        <el-col :span="7">
                            <el-form-item label="团队名称" prop="team_name">
                                <el-input v-model="teamMsg.team_name" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="团队简称" prop="team_short_name">
                                <el-input v-model="teamMsg.team_short_name" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="团队类型" prop="team_type">
                                <el-input v-model="teamMsg.team_type" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:55px;height:42px">
                        <el-col :span="15">
                            <el-form-item label="所在地区" prop="in_area">
                                <!-- <el-input v-model="teamMsg.in_area" placeholder="请输入" style="width:630px"></el-input> -->
                                <el-cascader :options="options" v-model="teamMsg.in_area" @change="handleChange" style="width:630px">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:55px;height:42px">
                        <el-col :span="15">
                            <el-form-item label="详细地址" prop="detail_address">
                                <el-input v-model="teamMsg.detail_address" placeholder="请输入" style="width:630px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:55px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="联系人" prop="contact">
                                <el-input v-model="teamMsg.contact" placeholder="请输入" style="width: 187px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="联系方式" prop="mobile">
                                <el-input v-model="teamMsg.mobile" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:55px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="中跆协会员号">
                                <el-input v-model="teamMsg.z_member_number" placeholder="请输入" style="width: 231px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="省跆协会员号">
                                <el-input v-model="teamMsg.s_member_number" placeholder="请输入" style="width: 231px;"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="uploadlist" style="dispaly:flex">
                        <p class="p">实名认证</p>
                        <el-upload class="avatar-uploader1" :action=url :show-file-list="false" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" style="margin-left:0px">
                            <img v-if="imageUrl1" :src="imageUrl1" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader1-icon"></i>
                        </el-upload>
                        <el-upload class="avatar-uploader1" :action=url :show-file-list="false" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl2" :src="imageUrl2" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader1-icon"></i>
                        </el-upload>
                        <el-upload class="avatar-uploader1" :action=url :show-file-list="false" :on-success="handleAvatarSuccess3" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl3" :src="imageUrl3" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader1-icon"></i>
                        </el-upload>
                    </div>
                </el-form>
                <div class="btnlist">
                    <button class="cancel" v-if="key==1" @click="cannal">取消</button>
                    <button v-if="key!=2" class="ok" @click="submitForm('teamMsg')">确认</button>
                    <button v-else class="ok" @click="submitForm('teamMsg')">确认</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createTeam, getTeam } from '@/api/compet'
import header from '@/components/header.vue'
import { regionData, CodeToText } from "element-china-area-data";
export default {
    name: "noteam",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            teamMsg: {
                team_name: '',
                team_short_name: '',
                team_type: '',
                in_area: '',
                detail_address: '',
                contact: '',
                mobile: '',
                z_member_number: '',
                s_member_number: '',
                tel: '',
                in_area_C: '',
            },
            key: '',
            dialogImageUrl: "",
            options: regionData,
            selectedOptions: [],
            url: window.location.protocol + '//' + window.location.hostname + "/api/upload/image/file",
            // url: "https://sport.yiti-china.com/api/upload/image/file",
            imageUrl: '',
            imageUrl1: '',
            imageUrl2: '',
            imageUrl3: '',
            rules: {
                team_name: [
                    { required: true, message: '请输入团队名称', trigger: 'blur' },
                    // { min: 10, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
                ],
                team_short_name: [
                    { required: true, message: '请输入团队简称', trigger: 'blur' },
                ],
                team_type: [
                    { required: true, message: '请输入团队类型', trigger: 'blur' },
                ],
                in_area: [
                    { required: true, message: '请输入所在地区', trigger: 'blur' },
                ],
                detail_address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
                contact: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
            },
            match_id: '',
        };
    },
    mounted() {

    },
    created() {
        const { id, key } = this.$route.query
        this.key = key
        this.match_id = id
        if (key == 1) {
            this.getTeamInfo()
        }
        else if (key == 2) {
            this.getTeamInfo()
        }
    },
    components: {
        'my-header': header
    },
    methods: {
        handleChange() {
            console.log(this.options)
            var loc = "";
            for (let i = 0; i < this.teamMsg.in_area.length; i++) {
                loc += CodeToText[this.teamMsg.in_area[i]];
            }
            this.teamMsg.in_area_C = loc

        },
        getTeamInfo() {
            getTeam().then(res => {
                this.imageUrl = res.data.logo
                this.teamMsg.team_name = res.data.team_name
                this.teamMsg.team_short_name = res.data.team_short_name
                this.teamMsg.team_type = res.data.team_type
                this.teamMsg.in_area = res.data.in_city
                this.teamMsg.in_area_C = res.data.in_city_c
                this.teamMsg.detail_address = res.data.detail_address
                this.teamMsg.contact = res.data.contact
                this.teamMsg.z_member_number = res.data.z_member_number
                this.teamMsg.s_member_number = res.data.s_member_number
                this.teamMsg.mobile = res.data.mobile
                this.imageUrl1 = res.data.buiss_image
                this.imageUrl2 = res.data.face_image
                this.imageUrl3 = res.data.back_image
            })
        },
        createdTeam() {
            createTeam({
                logo: this.imageUrl,
                team_name: this.teamMsg.team_name,
                team_short_name: this.teamMsg.team_short_name,
                team_type: this.teamMsg.team_type,
                in_area: this.teamMsg.in_area.join(','),
                in_area_C: this.teamMsg.in_area_C,
                detail_address: this.teamMsg.detail_address,
                contact: this.teamMsg.contact,
                z_member_number: this.teamMsg.z_member_number,
                s_member_number: this.teamMsg.s_member_number,
                mobile: this.teamMsg.mobile,
                buiss_image: this.imageUrl1,
                face_image: this.imageUrl2,
                back_image: this.imageUrl3,
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    if (this.key != 2) {

                        this.$router.push({
                            path: '/haveteam',
                            query: {
                                id: this.match_id
                            },
                        })
                    } else {
                        this.$router.push({
                            path: '/user',
                            query: {
                                id: this.match_id
                            },
                        })
                    }
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
            })
        },
        submitForm(teamMsg) {
            this.$refs[teamMsg].validate((valid) => {
                if (valid) {
                    this.createdTeam()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cannal() {
            this.$router.push({
                path: '/haveteam',
                query: {
                    id: this.match_id
                },
            })
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('只能上传图片!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl1 = res.data.path;
        },
        handleAvatarSuccess2(res, file) {
            this.imageUrl2 = res.data.path;
        },
        handleAvatarSuccess3(res, file) {
            this.imageUrl3 = res.data.path;
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data.path;
        },
    }
};
</script>

<style scoped>
#noteam {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
    position: absolute;
}
.getform {
    width: 90%;
    height: 864px;
    background: white;
    border-radius: 15px;
    /* margin-left: 127px; */
    margin: 0 auto;
    margin-top: 47px;
    display: flex;
}
/* 表单左侧 */
.form-left {
    width: 380px;
    height: 80%;
    margin-top: 64px;
}
/* 上传 */
.avatar-uploader {
    border: 1px solid #ededed;
    background-color: #fffeff;
    width: 209px;
    height: 266px;
    line-height: 266px;
    margin-left: 97px;
    border-radius: 5px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 209px;
    height: 266px;
    display: block;
}
/* 左侧提示 */
.left-tip {
    font-size: 14px;
    text-align: left;
    color: #929292;
    margin: 27px 0 0 95px;
}
.left-tip p {
    line-height: 14px;
    margin-top: 2px;
}
/* 表单右侧 */
.form-right {
    width: 1287px;
    height: 90%;
    margin-top: 64px;
    position: relative;
}
.el-upload--picture-card {
    width: 209px;
    height: 133px;
}
.uploadlist {
    width: 100%;
    display: flex;
    margin-top: 56px;
}
.p {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    margin-left: 47px;
}
.avatar-uploader1 {
    border: 1px solid #ededed;
    background-color: #fffeff;
    width: 209px;
    height: 133px;
    line-height: 133px;
    /* margin-left:97px; */
    border-radius: 5px;
}
.avatar-uploader1:nth-child(n + 1) {
    margin-left: 34px;
}
.avatar-uploader1 .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader1 .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader1-icon {
    font-size: 28px;
    color: #8c939d;
    width: 209px;
    height: 133px;
    line-height: 133px;
    text-align: center;
}
.avatar1 {
    width: 209px;
    height: 133px;
    display: block;
}
.btnlist {
    width: 100%;
    height: 40px;
    text-align: right;
    position: absolute;
    bottom: 0;
}
.btnlist button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    border: 2px solid #ff8f3a;
    font-size: 12px;
    line-height: 36px;
    font-weight: bold;
}
.cancel {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.ok {
    background-color: #ff8f3a;
    color: white;
    margin-right: 91px;
}
</style>