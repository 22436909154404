<template>
    <div id="index">
        <div class="swiper-container">
            <div class="swiper-button">
                <div class="button">
                    <el-input v-if="showHover" class="input" v-model="input" @change="selectComp" placeholder="请输入搜索内容"></el-input>
                    <div v-else style="width: 360px;margin-right: 30px;"></div>
                    <i class="el-icon-search" style="color:white" @click="shouInput"></i>
                    <button v-if="isLogin" class="btn1" @click="toRegister">注册</button>
                    <button v-if="isLogin" class="btn2" @click="toLogin">登录</button>
                    <button v-else class="btn2" @click="toUser">个人中心</button>
                </div>
            </div>
            <div class="swiper-wrapper">
                <swiper ref="mySwiper" :options="swiperOption">
                    <swiper-slide v-for="(img,index) in swiperList" :key="index" class="slide">
                        <img :src="img.url" alt="" width="100%" height="100%">
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="comp">
            <div class="comp-main">
                <p class="comp-title">赛事报名</p>
                <p class="comp-title-eng">Event registration</p>
                <div class="comp-tab">
                    <div class="tab-sport">
                        <span class="comp-tab-sport b" v-for="(item,index) in sportList" :key="index" @click="choiceSport(index)" :class="{'actSport':actSport === index}">
                            {{item.name}}
                        </span>
                    </div>
                    <div class="tab-type">
                        <el-dropdown>
                            <span class="el-dropdown-link b">
                                {{typeName}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="checkType(index)" v-for="(type,index) in typeList" :key="index">{{type.name}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div class="card-box">
                    <div class="comp-card" v-for="(comp,index) in compList" :key="index">
                        <div class="card-top">
                            <div class="card-type" v-if="comp.price > 0">
                                <span>付费</span>
                            </div>
                            <img :src='comp.image' alt="" width="100%" height="213px" style="border-radius:5px 5px 0 0 ">
                            <div class="card-main">
                                <div class="main-top">
                                    <i class="el-icon-location-outline" style="color:#FF8F3A;font-size:16px"></i>
                                    <!-- <span style="color:#686868;font-size:14px;margin:0 39px 0 4px">{{comp.area}}</span> -->
                                    <span style="color:#686868;font-size:14px;margin:0 39px 0 4px">{{comp.input_area}}</span>
                                    <i class="el-icon-alarm-clock" style="color:#FF8F3A;font-size:16px"></i>
                                    <span style="color:#686868;font-size:14px;margin:0 0 0 4px">{{comp.start_time}}</span>
                                </div>
                                <div class="line"></div>
                                <div class="main-down">
                                    <div>
                                        <p class="main-title">{{comp.match_name}}</p>
                                    </div>
                                    <div>
                                        <span class="main-tip" v-for="(tip,index) in comp.label_name" :key='index'>{{tip}}</span>
                                    </div>
                                </div>
                                <div class="main-button">
                                    <button type="button" class="main-btn" @click="toDetail(index)"><a>查看详情</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page">
                    <el-pagination @current-change="handleCurrentChange" :page-size="6" layout="total, prev, pager, next, jumper" :total="totalComp">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getType, getProj, getCompet, getSwiper } from '@/api/compet'
export default {
    components: {
        swiper,
        swiperSlide
    },
    name: "index",
    data() {
        return {
            swiperOption: {
                loop: true,
                autoplay: 5000,
                pagination: {
                    el: '.swiper-pagination'
                }
            },
            swiperList: [],
            input: "",
            sportList: [
                {
                    name: '全部',
                    id: 0
                }
            ],
            actSport: 0,
            actType: 0,
            typeList: [
                {
                    name: '全部',
                    id: 0
                }
            ],
            compList: [],
            currentPage: 6,
            totalComp: 0,
            showHover: false,
            input: '',
            typeName: '类型',
            page: 1,
            isLogin: true,
        };
    },
    created() {
        this.checkLogin()
        this.getswiperList()
        this.gettypeList()
        this.getprojList()
        this.getcompetList()
    },
    methods: {
        // 跳转个人中心
        toUser() {
            this.$router.push({
                path: '/user/'
            })
        },
        // 检查是否登录
        checkLogin() {
            if (localStorage.getItem('Authorization')) {
                this.isLogin = false
            }
        },
        // 赛事搜索
        selectComp() {
            this.getcompetList()
        },
        // 获取轮播图
        getswiperList() {
            getSwiper().then(res => {
                this.swiperList = res.data
            })
        },
        // 获取赛事列表
        getcompetList() {
            getCompet({
                page: this.page,
                limit: 6,
                category: this.typeList[this.actType].id,
                project: this.sportList[this.actSport].id,
                keyword: this.input
            }).then(res => {
                this.totalComp = res.data.total
                this.compList = res.data.data
            })
        },
        // 获取类型下拉选项
        gettypeList() {
            getType().then(res => {
                for (var a = 0; a < res.data.length; a++) {
                    this.typeList.push(res.data[a])
                }
            })
        },
        // 下拉选择类型
        checkType(index) {
            this.actType = index
            this.getcompetList()
        },
        // 获取赛事项目选项
        getprojList() {
            getProj().then(res => {
                for (var a = 0; a < res.data.length; a++) {
                    this.sportList.push(res.data[a])
                }
            })
        },
        // 点击选择类型
        choiceSport(index) {
            this.actSport = index
            this.getcompetList()
        },
        // 查看详情
        toDetail(index) {
            this.$router.push({
                path: '/detail',
                query: {
                    id: this.compList[index].match_id
                },
            });
        },
        // 去注册 1注册
        toRegister() {
            if (localStorage.getItem('Authorization')) {
                this.$message({
                    message: '您当前已登陆',
                    type: "warning",
                });
                return
            }
            this.$router.push({
                path: '/login',
                query: {
                    type: 1
                },
            })
        },
        // 去登陆 0登录
        toLogin() {
            if (localStorage.getItem('Authorization')) {
                this.$message({
                    message: '您当前已登陆',
                    type: "warning",
                });
                return
            }
            this.$router.push({
                path: '/login',
                query: {
                    type: 0
                },
            })
        },
        // 点击出现搜框
        shouInput() {
            if (this.showHover) {
                this.showHover = false
            } else {
                this.showHover = true
            }
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val
            this.getcompetList()
        }
    }
};
</script>

<style scoped>
@import "../../node_modules/swiper/dist/css/swiper.css";
/* 轮播 */
.swiper-button {
    position: absolute;
    height: 60px;
    width: 100%;
    z-index: 999;
    display: flex;
}
.button {
    position: absolute;
    right: 0;
    top: 53px;
    display: flex;
    line-height: 61px;
}
.input {
    width: 360px;
    margin-right: 30px;
}
.button i {
    color: white;
    font-size: 25px;
    margin-right: 61px;
    line-height: 61px;
}
.btn1 {
    width: 173px;
    height: 61px;
    background-color: white;
    color: #000;
    font-size: 18px;
    margin-right: 19px;
    border: none;
}
.btn2 {
    width: 173px;
    height: 61px;
    background-color: #ff8f3a;
    color: white;
    font-size: 18px;
    margin-right: 176px;
    border: none;
}
.swiper-container {
    /* width: 1000px;
  height: 450px;
  margin-top: 40px; */
    margin-bottom: 116px;
}
.swiper-slide img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}
.slide {
    width: 100%;
    height: 867px;
}
/* 赛事头 */
.comp {
    width: 100%;
}
.comp-main {
    width: 934px;
    text-align: center;
    /* margin-left: 493px; */
    height: 100px;
    margin: 0 auto;
}
.comp-title {
    font-size: 48px;
    color: #313131;
    /* font-weight: 550; */
    font-family: JDZY;
    line-height: 48px;
}
.comp-title-eng {
    font-size: 24px;
    color: #ff8f3a;
    font-weight: bold;
    line-height: 24px;
    margin-top: 21px;
}
/* 运动切换 */
.comp-tab {
    display: flex;
    margin-top: 78px;
    margin-bottom: 6px;
}
.tab-sport {
    font-size: 12px;
    color: white;
    width: 821px;
    display: flex;
    margin-top: 18px;
    margin-left: -9px;
}
.comp-tab-sport {
    width: 84px;
    height: 36px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #e8e8e8;
    line-height: 36px;
    margin-left: 9px;
}
.actSport {
    background-color: #ff8f3a;
}
/* 类型切换 */
.tab-type {
    margin-right: 0;
    width: 113px;
    height: 54px;
    background: #ff8f3a;
}
.el-dropdown-link {
    color: white;
    font-size: 18px;
    line-height: 54px;
}
/* 赛事卡 */
.card-box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    height: 980px;
}
.card-type {
    position: absolute;
    width: 68px;
    height: 26px;
    background: #ff8f3a;
    border-radius: 5px 0px 5px 0px;
}
.card-type span {
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
}
.comp-card {
    width: 300px;
    height: 446px;
    box-shadow: 0px 1px 11px 1px rgba(52, 52, 52, 0.1);
    border-radius: 5px;
    margin-top: 53px;
}
.comp-card:nth-child(n + 2) {
    margin-left: 17px;
}
.comp-card:nth-child(4) {
    margin-left: 0;
}
.card-main {
    width: 251px;
    text-align: center;
    margin-left: 25px;
}
.main-top {
    height: 50px;
    line-height: 50px;
}
.line {
    width: 100%;
    height: 1px;
    background-color: #e7e7e7;
}
.main-title {
    font-size: 14px;
    color: #131313;
    font-weight: bold;
    height: 56px;
    margin-top: 17px;
    line-height: 14px;
    text-align: left;
}
.main-tip:first-child {
    font-size: 12px;
    color: #c1c1c1;
    font-weight: bold;
    margin-top: 9px;
    line-height: 12px;
    float: left;
    padding: 0 6px 0 0;
    border-left: none;
}
.main-tip {
    font-size: 12px;
    color: #c1c1c1;
    font-weight: bold;
    margin-top: 9px;
    line-height: 12px;
    float: left;
    padding: 0 6px 0 6px;
    border-left: #c1c1c1 1px solid;
}
.main-button {
    text-align: left;
    margin-top: 49px;
}
.main-btn {
    width: 121px;
    height: 36px;
    background: #ff8f3a;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    line-height: 36px;
    border: none;
}
.main-btn a {
    text-decoration: none;
    color: white;
}
/* 分页 */
.page {
    float: right;
    padding-bottom: 50px;
}
</style>
