<template>
    <div id="setpage">
        <div class="head">
            <span class="head-title">账号设置</span>
            <button class="head-btn" @click='wantOut'>退出登录</button>
        </div>
        <div class="setpage-mian">
            <el-form :label-position="labelPosition" label-width="80px" :model="form">
                <el-form-item class='label' label="当前手机号">
                    <el-input class="input " v-model="form.tel" disabled></el-input>
                </el-form-item>
                <el-form-item class='label' label="新手机号">
                    <el-input class="input" v-model="form.new_tel" placeholder="请输入新手机号">
                    </el-input>
                </el-form-item>
                <el-form-item class='label' label="验证码">
                    <div class="bind_code margin_top">
                        <el-input class="bind_code_input code" v-model="form.code" type="text" placeholder="请输入验证码" />
                        <el-button @click.native.prevent="getCode" class="bind_code_gain" :disabled="disabled">{{ btnText }}</el-button>
                    </div>
                    <!-- <el-input class="input" v-model="form.code" placeholder="请输入验证码"></el-input> -->
                </el-form-item>
                <button class="btn" type="button" @click="changeMobile">完成</button>
            </el-form>
        </div>
    </div>
</template>

<script>
import { getcode } from '@/api/login'
import { setmobile, getuser, getuser1 } from '@/api/user'
export default {
    name: "setpage",
    data() {
        return {
            labelPosition: 'top',
            form: {
                tel: '',
                new_tel: "",
                code: '',
            },
            btnText: '获取验证码',
            disabled: false,
        };
    },
    created() {
        this.getInfo()
    },
    methods: {
        wantOut() {
            this.$confirm("是否退出当前账号?", "提示", {
                cancelButtonText: "取消",
                confirmButtonText: "确定",
                type: "warning"
            })
                .then(() => {
                    this.loginOut()
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出"
                    });
                });
        },
        loginOut() {
            localStorage.clear()
            this.$message({
                message: '已退出当前账号，请重新登陆',
                type: 'warning'
            })
            this.$router.push({
                path: '/login',
                query: {
                    type: 0
                }
            })
        },
        changeMobile() {
            setmobile({
                code: this.form.code,
                mobile: this.form.new_tel
            }).then(res => {
                console.log(res)
            })
        },
        getInfo() {
            getuser1().then(res => {
                this.form.tel = res.data.mobile
            })
        },
        getCode() {
            var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (!myreg.test(this.form.new_tel)) {
                this.$message({
                    message: '手机号格式不正确',
                    type: "error",
                });
                return false;
            }
            getcode({
                phone: this.form.new_tel,
                type: 'changeMobile'
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '发送成功',
                        type: "success",
                    });
                    this.bindforgetSendCode()
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
            })
        },
        bindforgetSendCode() {
            // this.$message("已发送验证码，请查收");
            this.disabled = true;
            this.btnText = "请稍候...";
            setTimeout(() => {
                this.doLoop(60);
            }, 500);
        },
        doLoop(seconds) {
            seconds = seconds ? seconds : 60;
            this.btnText = seconds + "s后获取";
            // this.code = code
            let countdown = setInterval(() => {
                if (seconds > 0) {
                    this.btnText = seconds + "s后获取";
                    --seconds;
                } else {
                    this.btnText = "获取验证码";
                    this.disabled = false;
                    clearInterval(countdown);
                }
            }, 1000);
        },
    }
};
</script>

<style scoped>
#setpage {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.head-btn {
    position: absolute;
    right: 36px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.setpage-mian {
    margin-left: 60px;
    margin-top: 56px;
    text-align: left;
    width: 430px;
}
.label {
    font-weight: bold;
    font-size: 14px;
}
.input {
    font-weight: bold;
    color: black;
    font-size: 14px;
    /* border: 2px solid #b2bddb; */
    outline: none;
    border-radius: 5px;
}
.btn {
    width: 430px;
    height: 58px;
    background: #ff8f3a;
    border-radius: 5px;
    color: #ffffff;
    font-size: 20px;
    border: none;
    margin-top: 80px;
}
.bind_code {
    position: relative;
}
.el-input__inner {
    width: 328px;
    height: 44px;
    background: #f7f7f7;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    outline: none;
    padding: 0 100px 0 10px;
}

.code /deep/.el-input__suffix {
    right: 97px;
}
.el-button {
    border: 0;
    background: none;
    padding: 0;
    border-radius: 0;
}
.pic {
    width: 80px;
    height: 25px;
    border-left: none !important;
}
.bind_code_gain {
    position: absolute;
    top: calc(50% - 9px);
    right: 10px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #20aee5;
    line-height: 18px;
    cursor: pointer;
    padding-left: 10px;
    border-left: 1px solid #d8d8d8;
}
.login-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 14px;
    left: 16px;
}
</style>
