<template>
    <div id="orderpage">
        <div class="head">
            <span class="head-title">订单中心</span>
            <!-- <button class="head-btn">添加人员</button> -->
        </div>
        <div class="orderpage-mian">
            <div class="main-tab">
                <div class="tab" v-for="(tab,index) in tabList" :key="index" :class="{'acttab' :actTab==index}" @click="changTab(index)">{{tab}}</div>
            </div>
            <div class="orderbox">
                <div class="order" v-for="(order,index) in orderList" :key="index">
                    <div class="msg">
                        <p class="title">{{order.match.match_name}}</p>
                        <p>报名时间：{{order.match.start_time}}</p>
                        <!-- <p>报名人：{{order.user}}</p> -->
                        <p>应缴金额：{{order.total_money}}</p>
                        <p>已缴金额：{{order.real_money}}</p>
                        <p v-if="order.diff_money>0">需补缴金额：{{order.diff_money}}</p>
                        <p v-if="order.status==3">失败原因：{{order.reason}}</p>
                        <p v-if="order.diff_money<0">应退金额：{{order.diff_money}}</p>
                    </div>
                    <button v-if="order.status==2" class="btn" @click="seeDetail(index)">查看报名表</button>
                    <button v-if="order.status==0" class="btn" @click="toPay(index)">去支付</button>
                    <button v-if="order.status==3" class="btn" @click="toChange(index)">去修改</button>
                    <p v-if="order.status==0" class="type">待支付</p>
                    <p v-if="order.status==1" class="type">审核中</p>
                    <p v-if="order.status==2" class="type">报名成功</p>
                    <p v-if="order.status==3" class="type">审核失败</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
export default {
    name: "orderpage",
    data() {
        return {
            tabList: ['全部', '待支付', '审核中', '报名成功'],
            actTab: '',
            orderList: [
            ]
        };
    },
    created() {
        this.getOrder()
    },
    methods: {
        toChange(index) {
            this.$router.push({
                path: '/paylist',
                query: {
                    id: this.orderList[index].match.match_id,
                    type: 1
                },
            })
        },
        seeDetail(index) {
            this.$router.push({
                path: '/paylist',
                query: {
                    id: this.orderList[index].match.match_id,
                    type: 0
                },
            })
        },
        toPay(index) {
            this.$router.push({
                path: '/paydetail',
                query: {
                    id: this.orderList[index].match.match_id,
                    order_number: this.orderList[index].orderno
                },
            })
        },
        getOrder() {
            axios.get('/pc/order/getOrderList?page=1&limit=99&status=' + (this.actTab - 1)).then(res => {
                this.orderList = res.data.data
            })
        },
        changTab(index) {
            this.actTab = index
            this.getOrder()
        }
    }
};
</script>

<style scoped>
#orderpage {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.head-btn {
    position: absolute;
    right: 36px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.orderpage-mian {
    margin-left: 46px;
    margin-top: 31px;
}
.main-tab {
    display: flex;
}
.tab {
    width: 112px;
    height: 37px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    line-height: 37px;
    font-size: 16px;
    color: #686868;
}
.tab:nth-child(n + 2) {
    margin-left: 23px;
}
.acttab {
    background: #ff8f3a;
    color: white;
    border: 1px solid #ff8f3a;
}
.orderbox {
    display: flex;
    margin-top: 14pxpx;
    flex-wrap: wrap;
}
.order {
    width: 408px;
    height: 164px;
    box-shadow: 0px 1px 11px 1px rgba(52, 52, 52, 0.1);
    border-radius: 5px;
    display: flex;
    margin-top: 28px;
    position: relative;
}
.order:nth-child(3n + 2) {
    margin-left: 28px;
}
.order:nth-child(3n + 3) {
    margin-left: 28px;
}
.msg {
    text-align: left;
    font-size: 12px;
    color: #c1c1c1;
    line-height: 12px;
    margin: 21px 0 0 36px;
}
.title {
    font-size: 14px;
    color: #313131;
    line-height: 14px;
    font-weight: bold;
}
.msg p {
    margin-top: 7px;
}
.type {
    width: 85px;
    height: 26px;
    background: #ff8f3a;
    box-shadow: 0px 1px 11px 1px rgba(52, 52, 52, 0.03);
    color: white;
    border-radius: 0px 5px 0px 5px;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 26px;
}
.btn {
    width: 79px;
    height: 22px;
    background: #ff8f3a;
    border-radius: 5px;
    line-height: 22px;
    font-weight: bold;
    color: #ffffff;
    font-size: 12px;
    border: none;
    position: absolute;
    right: 25px;
    top: 83px;
}
</style>
