<template>
    <div id="applypage">
        <div class="head">
            <span class="head-title">报项中心</span>
        </div>
        <div class="applypage-mian">
            <div class="apply-box" v-for="(apply,index) in applyList" :key="index">
                <div class="msg">
                    <p class='long'>{{apply.match.match_name}}</p>
                    <p class="date">比赛开始时间：{{apply.match.begin_time[0]}}</p>
                </div>
                <button class="btn" @click="seedetail(index)">查看详情</button>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
export default {
    name: "applypage",
    data() {
        return {
            applyList: [
            ]
        };
    },
    created() {
        this.getApplyInfo()
    },
    methods: {
        getApplyInfo() {
            axios.get('/pc/order/getItemData?page=1&limit=999').then(res => {
                this.applyList = res.data.data
            })
        },
        changTab(index) {
            this.actTab = index
        },
        seedetail(index) {
            let Type = 0
            if (this.applyList[index].order) {
                if (this.applyList[index].order.status == 0 || this.applyList[index].order.status == 3) {
                    Type = 1
                }
            }
            this.$router.push({
                path: '/user/applydetail',
                query: {
                    id: this.applyList[index].match.match_id,
                    date: this.applyList[index].match.begin_time[0],
                    title: this.applyList[index].match.match_name,
                    type: Type
                },
            });
        },
    }
};
</script>

<style scoped>
#applypage {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.applypage-mian {
    margin-left: 51px;
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
}
.apply-box {
    width: 404px;
    height: 92px;
    background: #f7f7f7;
    border-radius: 5px;
    display: flex;
    position: relative;
    margin-top: 25px;
}
.apply-box:nth-child(3n + 2) {
    margin-left: 23px;
}
.apply-box:nth-child(3n + 3) {
    margin-left: 23px;
}
.msg {
    font-size: 14px;
    color: #929292;
    line-height: 14px;
    margin-left: 32px;
    margin-top: 22px;
    text-align: left;
}
.date {
    margin-top: 10px;
}
.btn {
    position: absolute;
    right: 29px;
    top: 28px;
    width: 121px;
    height: 36px;
    background: white;
    border-radius: 5px;
    color: #464646;
    font-weight: bold;
    font-size: 12px;
    border: none;
}
.long {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
