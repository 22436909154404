<template>
    <div id="app">
        <nav>
            <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
        </nav>
        <router-view />
    </div>
</template>
<script>
export default {
    created() {
    },
    methods: {


    },
}
</script>
<style>
* {
    margin: 0;
    padding: 0;
    /* background-color: #f9fafe; */
}
html,
body,
#app {
    height: 100%;
}
.b {
    cursor: pointer;
}
button {
    cursor: pointer;
}
a {
    cursor: pointer;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif, JDZY;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* min-width: 1920px; */
}

nav {
    /* padding: 30px; */
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
