<template>
    <div id="detail">
        <my-header></my-header>
        <div class="main">
            <div class="main-top">
                <img class="top-img" :src="compDetail.image" alt="" width="300px" height="213px">
                <div class="top-msg">
                    <p class="msg-title">{{compDetail.match_name}}</p>
                    <p class="msg-date1">报名时间: {{compDetail.start_time}}~{{compDetail.end_time}}</p>
                    <p class="msg-date2">比赛时间: {{compDetail.b1_time}}~{{compDetail.b2_time}}</p>
                    <!-- <p class="msg-level">赛事级别: {{compDetail.level}}</p> -->
                    <p class="msg-area">赛事地址: {{compDetail.address}}</p>
                    <button class="msg-btn" @click="applyIt" v-if="compDetail.diff_time!=0">立即报名</button>
                    <p class="msg-book">赛事相关条例:
                        <span class="book1" @click="toRule(1)">《报名须知》</span>
                        <span class="book2 " @click="toRule(2)">《竞赛规程》</span>
                    </p>
                </div>
                <div class="wechat">
                    <img src="../../assets/img/ma.jpg" alt="" width="138px" height="138px">
                    <p>扫码进入小程序</p>
                </div>
            </div>
            <div class="main-down">
                <div class="down-tab">
                    <span class="tab-name" v-for="(item,index) in tabList" :key="index" @click="choiceTab(index)" :class="{'acttab':actTab === index}">
                        {{item}}
                    </span>
                    <div class="time">
                        <p class="time-title">报名结束倒计时</p>
                        <div class="timebox">
                            <div v-for="(time,i) in count" :key="i">{{time}}</div>
                        </div>
                    </div>
                    <!-- <div id="map" class="down-text" v-if="actTab==3"></div> -->
                    <div class="down-text" v-html="compTxt">

                    </div>
                    <div id="mymap" style="width: 100%; height:900px;"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from "@/utils/request";
import header from '@/components/header.vue'
export default {
    name: "detail",
    data() {
        return {
            count: [], //倒计时
            seconds: '', // 10天的秒数
            tabList: ['赛事主页', '秩序规则', '赞助商', '地图导航'],
            actTab: 0,
            compDetail: {},
            compTxt: '',
            a: 0,
            match_id: ''
        };
    },
    created() {
        const { id } = this.$route.query
        this.match_id = id
        this.getmatchDetail(id)
    },
    mounted() {
        this.Time() //调用定时器
    },
    components: {
        'my-header': header
    },
    methods: {
        toRule(i) {
            if (i == 1) {
                const { href } = this.$router.resolve({
                    // path 路由器配置
                    path: '/rule',
                    query: {
                        //  传参
                        'id': this.match_id,
                        'type': i
                    }
                })
                //打开空白页面
                window.open(href, '_blank')
            } else {
                const { href } = this.$router.resolve({
                    // path 路由器配置
                    path: '/rule',
                    query: {
                        //  传参
                        'id': this.match_id,
                        'type': 2
                    }
                })
                //打开空白页面
                window.open(href, '_blank')
            }
        },
        // 获取赛事详情
        getmatchDetail(id) {
            axios('pc/game/game_detail/' + id, {}).then(res => {
                this.compDetail = res.data
                this.latitude = res.data.latitude
                this.longitude = res.data.longitude
                this.compDetail.b1_time = this.compDetail.begin_time[0]
                this.compDetail.b2_time = this.compDetail.begin_time[1]
                this.seconds = this.compDetail.diff_time
                this.compTxt = this.compDetail.content.content
                var index = 0
                this.choiceTab(index)
            })
        },
        // 腾讯地图组件
        initMap() {
            //定义地图中心点坐标
            var center = new TMap.LatLng(this.latitude, this.longitude)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            var map = new TMap.Map(document.getElementById('mymap'), {
                center: center,//设置地图中心点坐标
                zoom: 17.2,   //设置地图缩放级别
                baseMap: {			//底图设置（参数为：VectorBaseMap对象）
                    type: 'vector',	//类型：失量底图
                }
            });
            this.markerLayer = new TMap.MultiMarker({
                map: map,  //指定地图容器
                //样式定义
                styles: {
                    //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                    "myStyle": new TMap.MarkerStyle({
                        "width": 35,  // 点标记样式宽度（像素）
                        "height": 50, // 点标记样式高度（像素）
                        // "src": 'map.png',  //图片路径 这里不设置，就会使用默认的样式
                        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                        "anchor": { x: 16, y: 32 }
                    })
                },
                //点标记数据数组
                geometries: [{
                    "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                    "styleId": 'myStyle',  //指定样式id
                    "position": center,  //点标记坐标位置
                }]
            })

            this.a = 1
        },
        // 结束时间拆分
        countDown() {
            let d = parseInt(this.seconds / (24 * 60 * 60))
            d = d < 10 ? "0" + d : d
            let h = parseInt(this.seconds / (60 * 60) % 24);
            h = h < 10 ? "0" + h : h
            let m = parseInt(this.seconds / 60 % 60);
            m = m < 10 ? "0" + m : m
            let s = parseInt(this.seconds % 60);
            s = s < 10 ? "0" + s : s
            this.count = [d, h, m, s]
        },
        //定时器没过1秒参数减1
        Time() {
            setInterval(() => {
                if (this.seconds <= 0) {
                    this.countDown()
                    return
                }
                this.seconds -= 1
                this.countDown()
            }, 1000)
        },
        // 赛事报名
        applyIt() {
            if (this.compDetail.is_begin == 0) {
                this.$message({
                    message: '比赛还未开始报名哦~',
                    type: 'error'
                })
                return
            }
            if (this.compDetail.is_begin == 0) {
                this.$message({
                    message: '比赛还未开始报名哦~',
                    type: 'error'
                })
                return
            }
            const token = localStorage.getItem('Authorization')
            if (!token) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                })
                this.$router.push({
                    path: '/login',
                    query: {
                        type: 0
                    },
                })
                return
            }
            axios.get('pc/team/orderstatus/' + this.match_id).then(res => {
                if (res.data == 0) {
                    this.$message({
                        message: '该赛事已报名成功，如需修改请联系管理员',
                        type: 'error'
                    })
                } else {
                    this.$router.push({
                        path: '/haveteam',
                        query: {
                            id: this.match_id,
                        },
                    })
                }
            })

        },
        // 详情富文本切换 0主页 1秩序 2赞助商 3地图
        choiceTab(index) {
            this.actTab = index
            if (index == 0) {
                this.compTxt = this.compDetail.content.content
                document.getElementById('mymap').style.display = "none"
            } else if (index == 1) {
                this.compTxt = this.compDetail.content.rule
                document.getElementById('mymap').style.display = "none"
            } else if (index == 2) {
                this.compTxt = this.compDetail.content.sponsor
                document.getElementById('mymap').style.display = "none"
            } else if (index == 3) {
                this.compTxt = ''
                document.getElementById('mymap').style.display = "block"
                if (this.a == 1) {
                    return
                }
                this.initMap()
            }
            this.compTxt = this.compTxt.replace(/\<img/gi, "<img style='width:100%;height:auto;'")
        },

    }
};
</script>

<style scoped>
#detail {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
    position: absolute;
}
#mymap {
    display: none;
}
.main {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 0 auto;
    /* margin-left: 359px; */
    margin-top: 34px;
}
/* 下方信息框 */
.main-down {
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: white;
    border-radius: 15px;
    margin-top: 24px;
}
.down-tab {
    padding-top: 27px;
}
.tab-name {
    padding: 12px 37px 12px 36px;
    line-height: 36px;
    font-size: 12px;
    font-weight: 550;
    color: #686868;
    border: 1px #686868 solid;
}
.tab-name:nth-child(n + 2) {
    border-left: none;
}
.acttab {
    border: #ff8f3a 1px solid;
    color: #ff8f3a;
}
.down-text {
    width: 90%;
    margin: 0 auto;
    height: auto;
    /* background-color: antiquewhite; */
    /* margin-left: 52px; */
}
.down-text1 {
    width: 1098px;
    height: 1098px;
    /* background-color: antiquewhite; */
    margin-left: 52px;
}
/* 上方信息框 */
.main-top {
    height: 287px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 27px 0px RGBA(247, 248, 254, 0.59);
    display: flex;
}
.top-img {
    margin: 33px 0 0 36px;
    border-radius: 5px 5px 0 0;
}
.top-msg {
    text-align: left;
    width: 600px;
    height: 213px;
    margin: 39px 0 0 49px;
}
.msg-title {
    font-size: 24px;
    font-weight: bold;
    color: #313131;
    line-height: 24px;
}
.msg-date1 {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 29px;
}
.msg-date2 {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 11px;
}
.msg-level {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 11px;
}
.msg-area {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 10px;
}
.msg-btn {
    width: 121px;
    border: none;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
    background-color: #ff8f3a;
    margin-top: 14px;
}
.msg-book {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 12px;
}
.book1 {
    cursor: pointer;
}
.book2 {
    cursor: pointer;
}
.msg-book span {
    color: #ff8f3a;
    margin-left: 15px;
}
.wechat {
    width: 138px;
    height: 200px;
    margin-top: 76px;
}
.wechat p {
    font-size: 12px;
    color: #686868;
    margin-top: 14px;
    font-weight: 550;
}
.time {
    width: 100%;
    height: auto;
}
.time-title {
    font-size: 16px;
    font-weight: bold;
    color: #31456c;
    margin-top: 24px;
}
.timebox {

    display: flex;
    width: 100%;
    margin: 0 auto;
    display: flex;justify-content: center;
    /* margin-left: 424px; */
    padding-bottom: 39px;
    margin-top: 21px;
}
.timebox div {
    height: 53px;
    line-height: 53px;
    width: 75px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 25px;
    color: white;
    margin-left: 15px;
    background-color: #31456c;
}
</style>
