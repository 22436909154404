<template>
    <div id="paydetail" :style="{ height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="paydetail">
            <div class="paydetail-head">
                <p class="head-title">付款详情</p>
            </div>
            <div class="paydetail-msg">
                <div class="paydetail-box">
                    <p class="paydetail-title">需缴纳费用</p>
                    <p class="paydetail-money">{{needMoney}}</p>
                </div>
                <div class="pay-box">
                    <p class="pay-title">对公收款</p>
                    <p class="pay-detail">账户名称：{{list.account_name}}</p>
                    <p class="pay-detail">账户号码：{{list.card_number}}</p>
                    <p class="pay-detail">开户银行：{{list.open_bank}}</p>
                    <p class="pay-title" style="margin-top:39px">银行转账</p>
                    <p class="pay-detail">账户名称：{{turn.turn_name}}</p>
                    <p class="pay-detail">账户号码：{{turn.turncard_num}}</p>
                    <p class="pay-title" style="margin-top:39px">二维码收款</p>
                    <img :src=img.turn_image alt="" width="127px" height="127px">
                    <p class="upload-detail">上传付款截图</p>
                    <el-upload class="avatar-uploader" :action="url" :show-file-list="false" :on-success="handleAvatarSuccess">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="btn">
                    <button class="btn1" @click="okPay">提交</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
import { getbank } from '@/api/compet'
import header from '@/components/header.vue'
export default {
    name: "paydetail",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            needMoney: '',
            url: window.location.protocol + '//' + window.location.hostname + "/api/upload/image/file",
            // url: "https://sport.yiti-china.com/api/upload/image/file",
            turn: {},
            list: {},
            img: {},
            imageUrl: '',
            order_number: ''
        };
    },
    created() {
        const { id, order_number } = this.$route.query
        this.match_id = id
        this.order_number = order_number
        this.getPrice()
        this.getBankInfo()
    },
    components: {
        'my-header': header
    },
    methods: {
        getPrice() {
            axios.get('/pc/team/signUpMoney/' + this.order_number).then(res => {
                this.needMoney = res.message
            })
        },
        okPay() {
            axios.post('/pc/team/payOrder/' + this.order_number, {
                path: this.imageUrl
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    })
                    this.$router.push({
                        path: '/user/order',
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
            })
        },
        getBankInfo() {
            getbank().then(res => {
                this.list = res.data.dg
                this.turn = res.data.yh
                this.img = res.data.image
            })
        },
        netxStep() {
            this.$router.push({
                path: '/haveleader'
            })
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data.path
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'; s
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    }
};
</script>

<style scoped>
#paydetail {
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.paydetail {
    width: 90%;
    margin: 0 auto;
    background-color: white;
    /* margin-left: 360px; */
    margin-top: 45px;
    padding-bottom: 30px;
    border-radius: 15px;
}
.paydetail-head {
    width: 100%;
    height: 80px;
    border-bottom: 1px #f1f1f1 solid;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: 550;
    color: #000000;
    line-height: 80px;
    margin-left: 59px;
}
.paydetail-msg {
    height: auto;
    background-color: white;
    width: 80%;
    margin-left: 73px;
}
.paydetail-box {
    width: 450px;
    height: 111px;
    background-color: #fff8e5;
    margin: 41px 0 0 0;
    border-radius: 5px;
}
.paydetail-title {
    display: flex;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 18px;
    padding-top: 25px;
    margin: 0 0 0 39px;
}
.paydetail-money {
    text-align: left;
    font-size: 26px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 26px;
    margin: 18px 0 0 44px;
}
.pay-box {
    text-align: left;
    margin-top: 20px;
}
.pay-title {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #313131;
    margin-bottom: 15px;
}
.pay-detail {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: #6f6f6f;
    margin-top: 10px;
}
.upload-detail {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: #6f6f6f;
    margin-top: 40px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader {
    margin-top: 11px;
    width: 60px;
    height: 60px;
    border: 2px #e3e6f3 solid;
    border-radius: 10px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.avatar {
    width: 60px;
    height: 60px;
    display: block;
}
.btn {
    margin-top: 18px;
}
.btn1 {
    width: 450px;
    height: 58px;
    background: #ff8f3a;
    border-radius: 5px;
    font-size: 20px;
    border: none;
    color: #ffffff;
}
</style>
