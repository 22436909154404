<template>
    <div id="personalpage">
        <div class="head">
            <span class="head-title">人员管理</span>
            <button class="head-btn" @click="addLerader">添加人员</button>
            <button class="head-btn1" @click="addplayer">添加运动员</button>
        </div>
        <div class="personalpage-mian">
            <div class="main-tab">
                <div class="tab" v-for="(tab,index) in tabList" :key="index" :class="{'acttab' :actTab==index}" @click="changTab(index)">{{tab}}</div>
            </div>
            <div class="userbox">
                <div class="user" v-for="(user,index) in userList" :key="index" @click="changeInfo(index)">
                    <img :src="user.avatar" alt="" width="35px" height="35px">
                    <div class="usermsg">
                        <p class="user-name">{{user.name}}</p>
                        <p class="user-tel">{{user.mobile}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
export default {
    name: "personalpage",
    data() {
        return {
            tabList: ['全部', '教练', '领队', '其他', '运动员'],
            actTab: 0,
            userList: [
            ]
        };
    },
    created() {
        this.getLeaderInfo()
    },
    methods: {
        addplayer() {
            this.$router.push({
                path: '/addplayer',
                query: {
                    id: 0,
                }
            })
        },
        addLerader() {
            this.$router.push({
                path: '/noleader',
                query: {
                    id: 0,
                }
            })
        },
        getLeaderInfo() {
            axios.get('/pc/team/teamManageList?ide_flag=' + this.actTab + '&flag=1').then(res => {
                this.userList = res.data
            })
        },
        changTab(index) {
            this.actTab = index
            this.getLeaderInfo()
        },
        changeInfo(index) {
            if (this.userList[index].ide_flag == 4) {
                this.$router.push({
                    path: '/addplayer',
                    query: {
                        id: 0,
                        sport_id: this.userList[index].id
                    }
                })
            }
            else {
                this.$router.push({
                    path: '/noleader',
                    query: {
                        id: 0,
                        leader_id: this.userList[index].id
                    }
                })
            }
        }
    }
};
</script>

<style scoped>
#personalpage {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.head-btn {
    position: absolute;
    right: 36px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.head-btn1 {
    position: absolute;
    right: 177px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.personalpage-mian {
    margin-left: 56px;
    margin-top: 31px;
}
.main-tab {
    display: flex;
}
.tab {
    width: 112px;
    height: 37px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    line-height: 37px;
    font-size: 16px;
    color: #686868;
}
.tab:nth-child(n + 2) {
    margin-left: 23px;
}
.acttab {
    background: #ff8f3a;
    color: white;
    border: 1px solid #ff8f3a;
}
.userbox {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 90%;
}
.user {
    width: 210px;
    height: 71px;
    background: #f7f7f7;
    border-radius: 5px;
    display: flex;
    margin-top: 40px;
}
.user:nth-child(5n + 2) {
    margin-left: 28px;
}
.user:nth-child(5n + 3) {
    margin-left: 28px;
}
.user:nth-child(5n + 4) {
    margin-left: 28px;
}
.user:nth-child(5n + 5) {
    margin-left: 28px;
}
.user img {
    border-radius: 50%;
    margin: 16px 0 0 18px;
}
.usermsg {
    color: #929292;
    font-size: 14px;
    text-align: left;
    margin: 14px 0 0 17px;
    line-height: 14px;
}
.user-tel {
    margin-top: 10px;
}
</style>
