<template>
    <div id="user" :style="{ height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="user">
            <div class="user-left">
                <div class="user-message">
                    <img :src="user ? user.logo : ''" alt="">
                    <p>{{user.team_name}}</p>
                </div>
                <div class="user-nav">
                    <div class="nav-box" v-for="(nav,index) in navList" :key="index">
                        <p class="nav">{{nav.name}}</p>
                        <router-link class="nav-title" v-for="(title,i) in nav.list" :key='i' :to="{name: title.path,query:nav.query}">{{title.title}}</router-link>
                    </div>
                </div>
            </div>
            <div class="user-right">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { getuser } from '@/api/user'
import header from '@/components/header.vue'
export default {
    name: "user",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            user: {
                img: require('@/assets/img/1.png'),
                name: '某某团队',
            },
            navList: [
                {
                    name: '团队中心',
                    list: [{
                        title: '团体主页',
                        path: 'team',
                        query: this.user
                    }, {
                        title: '人员管理',
                        path: 'personal'
                    }],
                },
                {
                    name: '报项中心',
                    list: [{
                        title: '报项中心',
                        path: 'apply'
                    }],
                },
                {
                    name: '订单中心',
                    list: [{
                        title: '订单中心',
                        path: 'order'
                    }],
                },
                {
                    name: '账号中心',
                    list: [{
                        title: '账号设置',
                        path: 'set'
                    }],
                },
            ]
        }
    },
    created() {
        this.getUserInfo()
    },
    components: {
        'my-header': header
    },
    methods: {
        getUserInfo() {
            getuser().then(res => {
                this.user = res.data
            })
        },
        changPath(index, i) {
            console.log(index, i)
        },
    }
};
</script>

<style scoped>
#user {
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.user {
    width: 90%;
    background-color: white;
    /* margin-left: 102px; */
    margin: 0 auto;
    margin-top: 45px;
    border-radius: 15px;
    display: flex;
}
.user-left {
    width: 15%;
    height: 850px;
    border: 1px #ededed solid;
    border-radius: 15px 0 0 15px;
    text-align: center;
}
.user-right {
    width: 85%;
}
.user-message {
    margin-top: 45px;
}
.user-message img {
    width: 137px;
    height: 137px;
    border-radius: 50%;
}
.user-message p {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-top: 32px;
    line-height: 18px;
}
.user-nav {
    margin-top: 29px;
}
.nav {
    margin-top: 52px;
    line-height: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}
.nav-title {
    display: block;
    margin-top: 32px;
    line-height: 16px;
    font-size: 16px;
    color: #686868;
    text-decoration: none;
}
</style>
