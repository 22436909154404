import { render, staticRenderFns } from "./playerslist.vue?vue&type=template&id=014038cd&scoped=true&"
import script from "./playerslist.vue?vue&type=script&lang=js&"
export * from "./playerslist.vue?vue&type=script&lang=js&"
import style0 from "./playerslist.vue?vue&type=style&index=0&id=014038cd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "014038cd",
  null
  
)

export default component.exports