import { axios } from "@/utils/request";

// 获取用户信息
export function getuser(data) {
    return axios({
        url: '/pc/team/team',
        method: 'get',
        data
    })
}

// 获取用户信息
export function getuser1(data) {
    return axios({
        url: '/pc/user/info',
        method: 'get',
        data
    })
}

//修改手机
export function setmobile(data) {
    return axios({
        url: '/pc/user/changePhone',
        method: 'post',
        data
    })
}