<template>
    <div id="user" :style="{ height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="user">
            <el-table :data="tableData" style="width: 100%" row-key="id" border lazy :load="load" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column prop="name" label="项目">
                </el-table-column>
                <!-- <el-table-column prop="name" label="姓名" width="180">
                </el-table-column> -->
                <!-- <el-table-column prop="address" label="地址">
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
import header from '@/components/header.vue'
export default {
    name: "user",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            tableData: [{
                id: 3,
                date: '2016-05-01',
                name: '个人品势',
                address: '上海市普陀区金沙江路 1519 弄',
                children: [{
                    id: 4,
                    date: '2016-05-01',
                    name: '儿童组',
                    address: '上海市普陀区金沙江路 1519 弄',
                    children: [{
                        id: 5,
                        date: '2016-05-01',
                        name: '男子组',
                        address: '上海市普陀区金沙江路 1519 弄',
                        children: [{
                            id: 6,
                            date: '2016-05-01',
                            name: '蓝带',
                            address: '上海市普陀区金沙江路 1519 弄',
                            children: [{
                                id: 7,
                                date: '2016-05-01',
                                name: '25Kg',
                                address: '上海市普陀区金沙江路 1519 弄',
                                children: [{
                                    id: 8,
                                    date: '2016-05-01',
                                    name: '太极二章',
                                    address: '上海市普陀区金沙江路 1519 弄',
                                }]
                            }]
                        }]
                    }, {
                        id: 9,
                        date: '2016-05-01',
                        name: '女子组',
                        address: '上海市普陀区金沙江路 1519 弄',
                        children: [{
                            id: 10,
                            date: '2016-05-01',
                            name: '蓝带',
                            address: '上海市普陀区金沙江路 1519 弄',
                            children: [{
                                id: 11,
                                date: '2016-05-01',
                                name: '25Kg',
                                address: '上海市普陀区金沙江路 1519 弄',
                                children: [{
                                    id: 12,
                                    date: '2016-05-01',
                                    name: '太极二章',
                                    address: '上海市普陀区金沙江路 1519 弄',
                                }]
                            }]
                        }]
                    }]
                }]
            }],
        }
    },
    created() {
    },
    components: {
        'my-header': header
    },
    methods: {

    }
};
</script>

<style scoped>
#user {
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.user {
    width: 1721px;
    background-color: white;
    margin-left: 102px;
    margin-top: 45px;
    border-radius: 15px;
    display: flex;
}
</style>
