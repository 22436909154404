<template>
    <div id="detail">
        <my-header></my-header>
        <div class="main">
            <div class="down-text" v-html="compTxt"></div>
        </div>
    </div>
</template>

<script>
import { axios } from "@/utils/request";
import header from '@/components/header.vue'
export default {
    name: "detail",
    data() {
        return {
            id: '',
            type: '',
            compTxt: '',
            compDetail: {}
        };
    },
    created() {
        const { id, type } = this.$route.query
        this.match_id = id
        this.type = type
        this.getmatchDetail(id)
    },
    mounted() {
    },
    components: {
        'my-header': header
    },
    methods: {
        getmatchDetail(id) {
            axios('pc/game/game_detail/' + id, {}).then(res => {
                this.compDetail = res.data
                if (this.type == 1) {
                    this.compTxt = this.compDetail.content.know
                } else {
                    this.compTxt = this.compDetail.content.rule
                }
                this.compTxt = this.compTxt.replace(/\<img/gi, "<img style='width:100%;height:auto;'")
            })
        },

    }
};
</script>

<style scoped>
#detail {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
    position: absolute;
}
#mymap {
    display: none;
}
.main {
    box-sizing: border-box;
    padding-bottom: 50px;
    width: 1203px;
    height: auto;
    text-align: center;
    /* margin-left: 359px; */
    margin: 0 auto;
    margin-top: 34px;
}
/* 下方信息框 */
.main-down {
    width: 1230px;
    height: auto;
    background-color: white;
    border-radius: 15px;
    margin-top: 24px;
}
.down-tab {
    padding-top: 27px;
}
.tab-name {
    padding: 12px 37px 12px 36px;
    line-height: 36px;
    font-size: 12px;
    font-weight: 550;
    color: #686868;
    border: 1px #686868 solid;
}
.tab-name:nth-child(n + 2) {
    border-left: none;
}
.acttab {
    border: #ff8f3a 1px solid;
    color: #ff8f3a;
}
.down-text {
    width: 1098px;
    height: auto;
    /* background-color: antiquewhite; */
    margin-left: 52px;
}
.down-text1 {
    width: 1098px;
    height: 1098px;
    /* background-color: antiquewhite; */
    margin-left: 52px;
}
/* 上方信息框 */
.main-top {
    height: 287px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 27px 0px RGBA(247, 248, 254, 0.59);
    display: flex;
}
.top-img {
    margin: 33px 0 0 36px;
    border-radius: 5px 5px 0 0;
}
.top-msg {
    text-align: left;
    width: 600px;
    height: 213px;
    margin: 39px 0 0 49px;
}
.msg-title {
    font-size: 24px;
    font-weight: bold;
    color: #313131;
    line-height: 24px;
}
.msg-date1 {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 29px;
}
.msg-date2 {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 11px;
}
.msg-level {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 11px;
}
.msg-area {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 10px;
}
.msg-btn {
    width: 121px;
    border: none;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
    background-color: #ff8f3a;
    margin-top: 14px;
}
.msg-book {
    font-size: 12px;
    color: #686868;
    line-height: 12px;
    margin-top: 12px;
}
.msg-book span {
    color: #ff8f3a;
    margin-left: 15px;
}
.wechat {
    width: 138px;
    height: 200px;
    margin-top: 76px;
}
.wechat p {
    font-size: 12px;
    color: #686868;
    margin-top: 14px;
    font-weight: 550;
}
.time {
    width: 100%;
    height: auto;
}
.time-title {
    font-size: 16px;
    font-weight: bold;
    color: #31456c;
    margin-top: 24px;
}
.timebox {
    margin-top: 21px;
    display: flex;
    margin-left: 424px;
    padding-bottom: 39px;
}
.timebox div {
    height: 53px;
    line-height: 53px;
    width: 75px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 25px;
    color: white;
    margin-left: 15px;
    background-color: #31456c;
}
</style>
