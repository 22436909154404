<template>
    <div id="noleader" :style="{height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="getform">
            <div class="form-left">
                <el-upload class="avatar-uploader" :action=url :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="left-tip">
                    <p>请上传彩色照片</p>
                    <p>文件格式:JPG,JPEG,PNG</p>
                    <p>诈照片大小不能超过3M</p>
                </div>
            </div>
            <div class="form-right">
                <el-form :inline="true" :model="teamMsg" class="demo-form-inline" label-position="right" :rules="rules" ref="teamMsg">
                    <el-row style="height:42px">
                        <el-col :span="7">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="teamMsg.name" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="国籍" prop="nationality">
                                <!-- <el-input v-model="teamMsg.c_name" placeholder="请输入"></el-input> -->
                                <el-select @change="nationality" v-model="teamMsg.nationality" filterable placeholder="请选择" style="width:205px">
                                    <el-option v-for="item in countryList" :key="item.id" :label="item.cname" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="民族" prop="national" v-show="isChain">
                                <!-- <el-input v-model="teamMsg.type" placeholder="请输入"></el-input> -->
                                <el-select v-model="teamMsg.national" placeholder="请选择" style="width:205px">
                                    <el-option v-for="item in nationList" :key="item.ethnic_id" :label="item.ethnic_name" :value="item.ethnic_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-18px;margin-top:26px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="证件类型" prop="card_type">
                                <!-- <el-input v-model="teamMsg.user" placeholder="请输入" ></el-input> -->
                                <el-select v-model="teamMsg.card_type" placeholder="请选择" style="width:205px">
                                    <el-option v-for="item in cardList" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="证件号码" prop="card_number">
                                <el-input @blur="getNunmerInfo" v-model="teamMsg.card_number" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:26px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="姓别" prop="sex">
                                <!-- <el-input v-model="teamMsg.name" placeholder="请输入"></el-input> -->
                                <el-select v-model="teamMsg.sex" placeholder="请选择" style="width:205px">
                                    <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" style="margin-left:-9px;">
                            <el-form-item label="出生日期" prop="birthday">
                                <el-date-picker v-model="teamMsg.birthday" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" style="width:205px">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="联系方式" prop="mobile">
                                <el-input v-model="teamMsg.mobile" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-32px;margin-top:26px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="中跆协会员号">
                                <el-input v-model="teamMsg.z_member_number" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="省跆协会员号">
                                <el-input v-model="teamMsg.s_member_number" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-9px;margin-top:26px;height:42px">
                        <el-col :span="14">
                            <el-form-item label="所在地区" prop="in_area">
                                <!-- <el-input v-model="teamMsg.in_area" placeholder="请输入" style="width:590px"></el-input> -->
                                <el-cascader :options="options" v-model="teamMsg.in_area" @change="handleChange" style="width:590px">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-9px;margin-top:26px;height:42px">
                        <el-col :span="14">
                            <el-form-item label="详细地址" prop="detail_address">
                                <el-input v-model="teamMsg.detail_address" placeholder="请输入" style="width:590px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-24px;margin-top:26px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="紧急联系人">
                                <el-input v-model="teamMsg.emergency_contact" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="紧急联系方式">
                                <el-input v-model="teamMsg.emergency_mobile" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="与本人关系">
                                <!-- <el-input v-model="teamMsg.number2" placeholder="请输入"></el-input> -->
                                <el-select v-model="teamMsg.relation" placeholder="请选择" style="width:205px">
                                    <el-option v-for="item in mixList" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-left:-9px;margin-top:26px;height:42px">
                        <el-col :span="7">
                            <el-form-item label="身份标签">
                                <el-checkbox-group v-model="teamMsg.ide_flag" @change="changeFlag">
                                    <el-checkbox v-for="body in bodyList" :label="body.value" :key="body.value">{{body.label}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="uploadlist" style="dispaly:flex">
                        <p class="p">实名认证</p>
                        <el-upload class="avatar-uploader1" :action="url" :show-file-list="false" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" style="margin-left:0px">
                            <img v-if="imageUrl1" :src="imageUrl1" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader1-icon"></i>
                        </el-upload>
                        <el-upload class="avatar-uploader1" :action="url" :show-file-list="false" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl2" :src="imageUrl2" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader1-icon"></i>
                        </el-upload>
                    </div>
                </el-form>
                <div class="btnlist" v-if="match_id!=0">
                    <button class="cancel" @click='cancel'>取消</button>
                    <button class="ok" @click="submitForm('teamMsg')">确认</button>
                </div>
                <div class="btnlist" v-else>
                    <button class="cancel" @click='cancel1'>取消</button>
                    <button class="ok" @click="submitForm('teamMsg')">保存</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from "@/utils/request";
import { getnational, getcountry, addleader, getarea } from '@/api/compet'
import { regionData, CodeToText } from "element-china-area-data";
import header from '@/components/header.vue'
import { Form } from 'element-ui';
export default {
    name: "noleader",
    data() {
        return {
            url: window.location.protocol + '//' + window.location.hostname + "/api/upload/image/file",
            // url: "https://sport.yiti-china.com/api/upload/image/file",
            screenHeight: document.body.clientHeight,
            imageUrl: '',
            imageUrl1: '',
            imageUrl2: '',
            isChain: true,
            options: regionData,
            selectedOptions: [],
            teamMsg: {
                name: '',
                nationality: '',
                national: '',
                card_type: '',
                card_number: '',
                sex: '',
                birthday: '',
                mobile: '',
                in_area: '',
                detail_address: '',
                z_member_number: '',
                s_member_number: '',
                emergency_contact: '',
                emergency_mobile: '',
                relation: '',
                face_image: '',
                ide_flag: [],
                back_image: '',
                in_area_C: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    // { min: 10, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
                ],
                nationality: [
                    { required: true, message: '请选择国籍', trigger: 'change' },
                ],
                national: [
                    { required: true, message: '请选择民族', trigger: 'change' },
                ],
                card_type: [
                    { required: true, message: '请选择证件类型', trigger: 'change' },
                ],
                card_number: [
                    { required: true, message: '请输入证件号码', trigger: 'blur' },
                ],
                in_area: [
                    { required: true, message: '请输入所在地区', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                detail_address: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
                sex: [
                    { required: true, message: '请选择性别', trigger: 'change' },
                ],
                birthday: [
                    { required: true, message: '请选择出生日期', trigger: 'change' },
                    // { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
            },
            bodyList: [{ value: 1, label: '教练' }, { value: 2, label: '领队' }, { value: 3, label: '其他' }],
            dialogImageUrl: "",
            countryList: [],
            nationList: [],
            cardList: [{ value: '身份证', label: '身份证' }, { value: '护照', label: '护照' }, { value: '其他', label: '其他' }],
            sexList: [{ value: '男', label: '男' }, { value: '女', label: '女' }],
            mixList: [{ value: '学员', label: '学员' }, { value: '家人', label: '家人' }, { value: '其他', label: '其他' }],
            match_id: 0,
            leader_id: 0,
        };
    },
    mounted() {
    },
    created() {
        const { id } = this.$route.query
        this.match_id = id
        if (this.$route.query.leader_id) {

            this.leader_id = this.$route.query.leader_id
            this.getLeaderInfo()
        }
        this.getNational()
        this.getCountry()
    },
    components: {
        'my-header': header
    },
    methods: {
        handleChange() {
            console.log(this.options)
            var loc = "";
            for (let i = 0; i < this.teamMsg.in_area.length; i++) {
                loc += CodeToText[this.teamMsg.in_area[i]];
            }
            this.teamMsg.in_area_C = loc

        },
        getLeaderInfo() {
            axios.get('/pc/team/teamManage/' + this.leader_id).then(res => {
                this.imageUrl = res.data.avatar
                this.teamMsg.name = res.data.name
                this.teamMsg.nationality = Number(res.data.nationality)
                this.teamMsg.national = Number(res.data.national)
                this.teamMsg.card_type = res.data.card_type
                this.teamMsg.card_number = res.data.card_number
                this.teamMsg.sex = res.data.sex_text
                this.teamMsg.birthday = res.data.birthday
                this.teamMsg.mobile = res.data.mobile
                this.teamMsg.in_area = res.data.in_city
                this.teamMsg.in_area_C = res.data.in_city_c
                this.teamMsg.detail_address = res.data.detail_address
                this.teamMsg.z_member_number = res.data.z_member_number
                this.teamMsg.s_member_number = res.data.s_member_number
                this.teamMsg.emergency_contact = res.data.emergency_contact
                this.teamMsg.emergency_mobile = res.data.emergency_mobile
                this.teamMsg.relation = res.data.relation
                res.data.ide_flag.map(item => {
                    this.teamMsg.ide_flag.push(Number(item))
                })
                this.imageUrl1 = res.data.face_image
                this.imageUrl2 = res.data.back_image
                console.log(this.teamMsg.ide_flag)

            })
        },
        getNunmerInfo() {
            if (this.teamMsg.card_type == '身份证') {
                var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
                if (regIdCard.test(this.teamMsg.card_number)) {
                    this.go(this.teamMsg.card_number.length);
                } else {
                    this.$message({
                        message: '身份证格式有误',
                        type: "error",
                    });
                }
            }
        },
        go(val) {
            let iden = this.teamMsg.card_number;
            let sex = null;
            let birth = null;
            let myDate = new Date();
            let month = myDate.getMonth() + 1;
            let day = myDate.getDate();
            let age = 0;

            if (val === 18) {
                age = myDate.getFullYear() - iden.substring(6, 10) - 1;
                sex = iden.substring(16, 17);
                birth = iden.substring(6, 10) + "-" + iden.substring(10, 12) + "-" + iden.substring(12, 14);
                if (iden.substring(10, 12) < month || iden.substring(10, 12) == month && iden.substring(12, 14) <= day) age++;

            }
            if (val === 15) {
                age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
                sex = iden.substring(13, 14);
                birth = "19" + iden.substring(6, 8) + "-" + iden.substring(8, 10) + "-" + iden.substring(10, 12);
                if (iden.substring(8, 10) < month || iden.substring(8, 10) == month && iden.substring(10, 12) <= day) age++;
            }

            if (sex % 2 === 0)
                sex = '女';
            else
                sex = '男';
            this.teamMsg.sex = sex;
            this.teamMsg.birthday = birth;
        },
        nationality() {
            if (this.teamMsg.nationality == 103 || this.teamMsg.nationality == 77 || this.teamMsg.nationality == 73 || this.teamMsg.nationality == 102) {
                this.isChain = true
            } else {
                this.isChain = false
            }
        },
        submitForm(teamMsg) {
            if (this.isChain == false) {
                this.createLeader()
            } else {
                this.$refs[teamMsg].validate((valid) => {
                    if (valid) {
                        this.createLeader()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        createLeader() {
            axios.post('/pc/team/addManage/' + this.leader_id, {
                avatar: this.imageUrl,
                name: this.teamMsg.name,
                nationality: this.teamMsg.nationality,
                national: this.teamMsg.national,
                card_type: this.teamMsg.card_type,
                card_number: this.teamMsg.card_number,
                sex: this.teamMsg.sex,
                birthday: this.teamMsg.birthday,
                mobile: this.teamMsg.mobile,
                in_area: this.teamMsg.in_area.join(','),
                in_area_C: this.teamMsg.in_area_C,
                detail_address: this.teamMsg.detail_address,
                z_member_number: this.teamMsg.z_member_number,
                s_member_number: this.teamMsg.s_member_number,
                emergency_contact: this.teamMsg.emergency_contact,
                emergency_mobile: this.teamMsg.emergency_mobile,
                relation: this.teamMsg.relation,
                ide_flag: this.teamMsg.ide_flag.join(','),
                face_image: this.imageUrl1,
                back_image: this.imageUrl2,
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    if (this.match_id == 0) {
                        this.$router.push({
                            path: '/user/personal',
                        })
                    } else {
                        this.$router.push({
                            path: '/haveleader',
                            query: {
                                id: this.match_id
                            },
                        })
                    }
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
            })
        },
        cancel() {
            this.$router.push({
                path: '/haveleader',
                query: {
                    id: this.match_id
                },
            })
        },
        cancel1() {
            this.$router.push({
                path: '/user/personal',
            })
        },
        getNational() {
            getnational().then(res => {
                this.nationList = res.data
            })
        },
        getCountry() {
            getcountry().then(res => {
                // this.countryList = res.data
                for (var a = 0; a < res.data.length; a++) {
                    this.countryList.push(res.data[a])
                }
            })
        },
        // 多选
        changeFlag(val) {
            this.teamMsg.ide_flag = val
            console.log(val)
            console.log(this.teamMsg.ide_flag)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('只能上传图片!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data.path;
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl1 = res.data.path;
        },
        handleAvatarSuccess2(res, file) {
            this.imageUrl2 = res.data.path;
        },
    }
};
</script>

<style scoped>
#noleader {
    height: 663px;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.getform {
    width: 1667px;
    height: 864px;
    background: white;
    border-radius: 15px;
    margin-left: 127px;
    margin-top: 47px;
    display: flex;
}
/* 表单左侧 */
.form-left {
    width: 380px;
    height: 80%;
    margin-top: 64px;
}
/* 上传 */
.avatar-uploader {
    border: 1px solid #ededed;
    background-color: #fffeff;
    width: 209px;
    height: 266px;
    line-height: 266px;
    margin-left: 97px;
    border-radius: 5px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 209px;
    height: 266px;
    display: block;
}
/* 左侧提示 */
.left-tip {
    font-size: 14px;
    text-align: left;
    color: #929292;
    margin: 27px 0 0 95px;
}
.left-tip p {
    line-height: 14px;
    margin-top: 2px;
}
/* 表单右侧 */
.form-right {
    width: 1287px;
    height: 90%;
    margin-top: 64px;
    position: relative;
}
.el-upload--picture-card {
    width: 209px;
    height: 133px;
}
.uploadlist {
    width: 100%;
    display: flex;
    margin-top: 26px;
    margin-left: -14px;
}
.p {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    margin-left: 47px;
}
.avatar-uploader1 {
    border: 1px solid #ededed;
    background-color: #fffeff;
    width: 209px;
    height: 133px;
    line-height: 133px;
    /* margin-left:97px; */
    border-radius: 5px;
}
.avatar-uploader1:nth-child(n + 1) {
    margin-left: 34px;
}
.avatar-uploader1 .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader1 .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader1-icon {
    font-size: 28px;
    color: #8c939d;
    width: 209px;
    height: 133px;
    line-height: 133px;
    text-align: center;
}
.avatar1 {
    width: 209px;
    height: 133px;
    display: block;
}
.btnlist {
    width: 100%;
    height: 40px;
    text-align: right;
    position: absolute;
    bottom: 0;
}
.btnlist button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    border: 2px solid #ff8f3a;
    font-size: 12px;
    line-height: 36px;
    font-weight: bold;
}
.cancel {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.ok {
    background-color: #ff8f3a;
    color: white;
    margin-right: 91px;
}
</style>