<template>
    <div id="headerd">
        <div class="headers">
            <div @click="toHome">
                <img src="../assets/img/logo.png" alt="" width="52px" height="52px">
            </div>
            <div class="btn">
                <button class="header-button" @click="toUser">个人中心</button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "headerd",
    data() {
        return {

        };
    },
    methods: {
        toHome() {
            this.$router.push({
                name: 'index'
            })
        },
        toUser() {
            this.$router.push('/user')
        }
    }
};
</script>

<style scoped>
#headerd {
    background-color: #f9fafe;
    width: 100%;
    height: 50px;
    position: relative;
}
.headers {
    width: 100%;
    height: 52px;
    display: flex;
    background-color: white;
}
.headers img {
    margin-left: 80px;
}
.btn {
    position: absolute;
    right: 135px;
}
.header-button {
    background-color: #ff8f3a;
    height: 41px;
    width: 173px;
    margin-top: 7px;
    color: white;
    border: none;
    font-size: 18px;
}
</style>
