<template>
    <div id="playersapply">
        <my-header></my-header>
        <div class="main-box">
            <div class="main-title">
                <p>选手报名</p>
                <button class="title-btn1" @click="getPlayers">选择人员</button>
                <button class="title-btn" @click="addPlayers">添加人员</button>
            </div>
            <div class="all-box" v-if="userList.length==0">
                <h1 style="line-height:300px">请先添加人员</h1>
            </div>
            <div v-else class="all-box">
                <div class="user-box">
                    <div class="inside">
                        <div v-for="(item,index) in userList" :key="index" class="user-btn" :class="{'actuser':actUser === index}">
                            <div class="user-name" :class="{'actuser':actUser === index}" @click="choiceUser(index)">{{item.name}}</div>
                            <button :class="{'actuser':actUser === index}" @click="deleteUser(index)">x</button>
                        </div>
                    </div>
                    <div class="userbtn">
                        <button @click="clearUser">清空运动员</button>
                    </div>
                </div>
                <div class="compet">
                    <div v-if="kindList.length==0" class="all-box">
                        <h1 style="line-height:100px">请先选择人员</h1>
                    </div>
                    <div class="kind" v-for="(kind,key) in kindList" :key="key">
                        <div v-if="kind.rule_type=='personal'" style="margin-top:45px;" class="a">
                            <p class="kindtitle">{{kind.name}}</p>
                            <div class="kindlist" v-for="(type,index) in kind.matchGroupChild" :key="index" @click="choiceKind(type,key,index)" :class="[index == kindList[key].selectIndex ? 'actkind' : '']">{{type.name}}</div>
                        </div>
                        <div v-else style="margin-top:45px" class="a">
                            <p class="kindtitle">{{kind.name}}</p>
                            <div class="kindlist2" v-for="(type,index) in kind.matchGroupChild" :key="index" @click="choiceKind1(type,key,index)" :class="[index == kindList[key].selectIndex ? 'actkind' : '']">
                                <span><i class="el-icon-user-solid"></i>{{type.name}}</span>
                                <!-- <el-select class="select" :disabled="index == kindList[key].selectIndex ? false : true" placeholder="" size='mini' v-model="type.kind" @change="apply">
                                    <el-option v-for="item in type.group_num" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ok-btn" v-if="kindList.length!=0">
                    <!-- <button class="btn-ok" @click="applyIt">查看报名表</button> -->
                </div>
            </div>
            <el-dialog title="添加运动员" :visible.sync="dialogFormVisible" width='800px'>
                <div class="header"></div>
                <div class="addtype">
                    <p class="add-title">添加方式</p>
                    <div class="typelist">
                        <div class="typetab" v-for="(tab1,index) in addList" :key="index" @click="choiceaAdd(index)" :class="{'actadd':actAdd === index}">{{tab1}}</div>
                    </div>
                </div>
                <div class="addtype">
                    <p class="add-title" style="margin:0">模板</p>
                    <div class="typelist">
                        <div class="typetab2"><a class="a" :href="url" download>下载模板</a></div>
                        <div class="typetab2" @click="uploadFile">上传模板</div>
                        <input v-show="false" ref="myfile" type="file" @change="fileChange($event)">
                    </div>
                </div>
                <button class="addbtn" @click="okIt">确认</button>
            </el-dialog>
        </div>
        <div class="applyed">
            <div class="team-msg">
                <p>已报名{{playerList_apply.length}}人</p>
                <el-table :data="playerList_apply" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}" :span-method='join' border>
                    <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
                    <el-table-column prop="z_index" label="序号" align="center" width="50"></el-table-column>
                    <el-table-column prop="sport" label="运动员" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.sport.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.group.rule_type=='personal'">{{scope.row.group.name}},{{scope.row.groupChild.name}},{{scope.row.groupLevel.level_name}}</span>
                            <span v-else>{{scope.row.group.name}},{{scope.row.groupChild.name}},{{scope.row.groupLevel.level_name}}{{scope.row.group_index}}组</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="btn-add">
                <button class="btn-up" @click="lastStep">上一步</button>
                <button class="btn-down" @click="netxStep">下一步</button>
            </div>
        </div>
        <el-dialog :title="titleName" :visible.sync="applydialogFormVisible" @close='noapply'>
            <div class="transfer">
                <div class="left">
                    <div class="left-header">
                        <span>可选成员</span>
                        <span></span>
                    </div>
                    <div class="left-main">
                        <el-radio-group v-model="radio" class="dan">
                            <el-radio class="left-line" v-for="(item,i) in leftList" :key="i" :label="i">{{item.name}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="mid"><i class="el-icon-d-arrow-right" @click="toRight"></i></div>
                <div class="right">
                    <div class="right-header">
                        <span>已选成员</span>
                        <span style="text-algin:right">{{rightList.length+1}}/{{maxNum}}</span>
                    </div>
                    <div class="right-main">
                        <el-radio-group v-model="radio1" class="dan1">
                            <el-radio class="right-line" v-for="(moren,index) in noList" :key="index" :label="index" disabled>{{moren.name}}</el-radio>
                        </el-radio-group>
                        <el-radio-group class="dan">
                            <el-radio class="right-line" v-for="(item,i) in rightList" :key="i" :label="i">{{item.name}}
                                <span class="del" @click="del(i)">x</span>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="b-btn">
                <el-button v-if="actSelect" type="danger" @click="deleteApply">删除当前报名</el-button>
                <el-button type="info" @click="noapply">取消</el-button>
                <el-button type="primary" @click="apply">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog title="选择运动员" :visible.sync="playerdialogFormVisible" :width="'900px'" style="margin-top:-10vh;">
            <el-form ref="form" :model="form" size="small " >
                <el-row class="elrow">
                    <el-col :span="6">
                        <el-form-item label="性别" label-width="50px">
                            <el-select v-model="form.sex" placeholder="请选择" clearable >
                                <el-option value="1" label="男"></el-option>
                                <el-option value="2" label="女"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="margin-left: 50px;">
                        <el-form-item label="出生日期" label-width="80px">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="姓名" label-width="50px">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="searchbtn">
                    <el-button type="primary" class="searchbtn" @click="serch">搜索</el-button>
                </div>
            </el-form>
            <div class="testbox">
                <el-table ref="multipleTable" :data="playerList" style="width: 100%;margin-top:60px;height:500px" algin='center' :header-cell-style="{background:'#F8F9FA'}" @selection-change="handleSelectionChange" :row-key="getRowKeys" height="500px">
                    <el-table-column type="selection" width="50" align="center" :reserve-selection="true"></el-table-column>
                    <el-table-column label="头像" width="100" align="center">
                        <template slot-scope="scope">
                            <div style="height:30px;width:60%;text-algin:center;margin-left:20%">
                                <img :src="scope.row.avatar" width="100%" height="100%">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" prop="name" width="80" align="center"></el-table-column>
                    <el-table-column prop="sex" label="性别 " align="center" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sex==1?'男':'女' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="birthday" label="出生日期" width="170" align="center"></el-table-column>
                    <el-table-column prop="mobile" label="手机号码" width="170" align="center"></el-table-column>
                    <el-table-column prop="card_number" label="证件号码" width="240" align="center"></el-table-column>
                </el-table>
            </div>
            <!-- <el-pagination style="margin-top: 20px;" @current-change="handleCurrentChange" :current-page="currentPage" background layout="total,prev, pager, next" :page-size="8" :total="total">
            </el-pagination> -->
            <button class="addbtn" @click="addIt">确认</button>
        </el-dialog>
    </div>
</template>

<script>
import { axios } from "@/utils/request";
import { uploadmb, getplayerlist, } from '@/api/compet'
import header from '@/components/header.vue'
export default {
    name: "playersapply",
    data() {
        return {
            radio: '',
            radio1: '',
            leftList: [],
            rightList: [],
            noList: [],
            titleName: '',
            url: window.location.protocol + '//' + window.location.hostname + "/运动员模板.xls",
            userList: [
            ],
            playerList: [],
            form: {
                sex: '',
                name: '',
                date: []
            },
            transferList: [],
            actUser: '',
            kindList: [],
            actKind: '',
            c_name: 0,
            applydialogFormVisible: false,
            dialogFormVisible: false,
            playerdialogFormVisible: false,
            addList: ['批量添加', '单独添加'],
            mbList: ['下载模板', '上传模板'],
            actAdd: 0,
            actMb: 0,
            match_id: '',
            sport_id: '',
            xlsList: [],
            sport: '',
            type: '',
            total: 0,
            cloneList: [],
            currentPage: 1,
            limit: 8,
            maxNum: '',
            applytype: '',
            actKey: '',
            actIndex: '',
            actSelect: false,
            playerList_apply: [],
            applytotal: 0,
        };
    },
    created() {
        const { id } = this.$route.query
        this.match_id = id
        if (this.$route.query.sport) {
            this.userList.push({
                id: this.$route.query.sport,
                name: this.$route.query.name,
                sex: this.$route.query.sex
            })
            this.choiceUser(0)
        }
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        }
        this.getList()
    },
    mounted() {
        this.check()
    },
    components: {
        'my-header': header
    },
    methods: {
        check() {
            axios.get('pc/team/orderstatus/' + this.match_id).then(res => {
                if (res.data == 0) {
                    this.$message({
                        message: '该赛事已报名成功，如需修改请联系管理员',
                        type: 'error'
                    })
                    this.$router.push({
                        path: '/detail',
                        query: {
                            id: this.match_id
                        }
                    })
                }
            })
        },
        // 删除当前报名
        deleteApply() {
            let key = this.actKey
            let index = this.actIndex
            let id = this.kindList[key].matchGroupChild[index].id
            axios.delete('pc/team/delSaveProject/' + this.sport_id + '/' + id + '/' + this.match_id).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                })
                this.kindList[key].selectIndex = -1
                this.noapply()
                this.getList()
            })
        },
        // 取消
        noapply() {
            this.applydialogFormVisible = false
            this.rightList = []
            this.actSelect = false
            this.titleName = ''
        },
        // 单删运动员
        deleteUser(index) {
            let a = this.actUser
            if (a == index) {
                this.actUser = ''
            }
            this.cloneList.splice(index, 1)
            this.userList = this.cloneList
        },
        // 删除右侧
        del(index) {
            this.leftList.push(this.rightList[index])
            this.rightList.splice(index, 1)
        },
        toRight() {
            console.log(this.kindList[this.actKey])
            if (this.kindList[this.actKey].rule_type == 'double') {
                if (this.kindList[this.actKey].double_type == 1) {
                    if (this.leftList[this.radio].sex == 2) {
                        this.$message({
                            message: '请添加性别符合要求得运动员',
                            type: 'warning'
                        })
                        return
                    }
                }
                if (this.kindList[this.actKey].double_type == 2) {
                    if (this.leftList[this.radio].sex == 1) {
                        this.$message({
                            message: '请添加性别符合要求得运动员',
                            type: 'warning'
                        })
                        return
                    }
                }
                if (this.kindList[this.actKey].double_type == 3) {
                    console.log(this.noList[0])
                    console.log(this.leftList[this.radio])
                    if (this.noList[0].sex == 1 && this.leftList[this.radio].sex != 2) {
                        this.$message({
                            message: '请添加性别符合要求得运动员',
                            type: 'warning'
                        })
                        return
                    } else if (this.noList[0].sex == 2 && this.leftList[this.radio].sex != 1) {
                        this.$message({
                            message: '请添加性别符合要求得运动员',
                            type: 'warning'
                        })
                        return
                    }
                }
            }
            if (this.rightList.length + 2 > this.maxNum) {
                this.$message({
                    message: '超过最大可选数量',
                    type: 'warning'
                })
                return
            }
            this.rightList.push(this.leftList[this.radio])
            this.leftList.splice(this.radio, 1)
            this.radio = ''
        },
        getapplyList(key, index) {
            this.applydialogFormVisible = true
            this.maxNum = this.kindList[key].num[1]
            let id = this.kindList[key].matchGroupChild[index].id
            let child_id = this.kindList[key].matchGroupChild[index].child_id
            axios.get('/pc/team/teamSportList?limit=999&match_id=' + this.match_id + '&group_id=' + child_id + '&group_level_id=' + id + '&sport_id=' + this.sport_id).then(res => {
                this.leftList = res.data.data
            })
            if (index == this.kindList[key].selectIndex) {
                axios.get('/pc/team/getsignsport/' + this.match_id + '/' + id + '/' + this.sport_id).then(res => {
                    this.rightList = res.data
                    this.actSelect = true
                })
            }
        },
        // 多人赛事
        choiceKind1(type, key, index) {
            console.log(this.kindList[key].selectIndex)
            if (this.kindList[key].hasOwnProperty('selectIndex')) {
                if (this.kindList[key].selectIndex != index && this.kindList[key].selectIndex != '-1') {
                    this.$message({
                        message: '请先删除当前报名',
                        type: 'error'
                    })
                    return
                }
            }
            this.actKey = key
            this.actIndex = index
            this.titleName = this.kindList[key].matchGroupChild[index].name
            this.applytype = ''
            this.getIndex()
            if (index == this.kindList[key].selectIndex) {
                this.getapplyList(key, index)
                // this.kindList[key].selectIndex = -1
            } else {
                this.setPlayer(key, index)
            }
        },
        // 选择运动员获取赛事
        setPlayer(key, index) {
            this.applydialogFormVisible = true
            this.maxNum = this.kindList[key].num[1]
            let id = this.kindList[key].matchGroupChild[index].id
            let child_id = this.kindList[key].matchGroupChild[index].child_id
            axios.get('/pc/team/teamSportList?limit=999&match_id=' + this.match_id + '&group_id=' + child_id + '&group_level_id=' + id + '&sport_id=' + this.sport_id).then(res => {
                this.leftList = res.data.data
            })
        },
        getIndex() {
            let id = this.kindList[this.actKey].matchGroupChild[this.actIndex].id
            axios.get('/pc/team/getGroupIndex/' + this.match_id + '/' + id + '/' + this.sport_id).then(res => {
                this.titleName = this.titleName + res.data + '组'
            })
        },
        // 选择运动员
        handleSelectionChange(val) {
            console.log(val)
            this.cloneList = val
        },
        //搜索
        serch() {
            console.log(this.form)
            this.getPlayer()
        },
        //选择运动员
        addIt() {
            this.kindList = []
            this.actUser = ''
            this.userList = this.cloneList
            this.playerdialogFormVisible = false
        },
        getPlayers() {
            this.playerdialogFormVisible = true
            this.getPlayer()
        },
        okIt() {
            this.dialogFormVisible = false
        },
        clearUser() {
            this.kindList = []
            this.userList = []
            this.actUser = ''
            this.noList = []
        },
        abc() {

        },
        applyIt() {
            this.getList()
            // this.$router.push({
            //     name: 'applylist',
            //     query: {
            //         id: this.match_id,
            //         type: this.type
            //     }
            // })
        },
        getPlayer() {
            let date = ''
            if (this.form.date) {
                date = this.form.date.join(",")
            }
            axios.get('/pc/team/teamSportList?limit=' + '999' + '&page=' + this.currentPage + '&sex=' + this.form.sex + '&date=' + date + '&name=' + this.form.name + '&match_id=' + this.match_id).then(res => {
                this.playerList = res.data.data
                if (this.sport != '') {
                    for (var a = 0; a < this.res.data.data.length; a++) {
                        if (this.res.data.data[a].id == this.sport) {
                            this.cloneList.push(this.res.data.data[a])
                            this.userList = this.cloneList
                            console.log(this.cloneList)
                            // this.choiceUser(a)
                        }
                    }
                    this.sport = ''
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
                this.total = res.data.total
            })
        },
        addPlayers() {
            this.dialogFormVisible = true
        },
        choiceaAdd(index) {
            this.actAdd = index
            if (index == 1) {
                this.$router.push({
                    name: 'addplayer',
                    query: {
                        id: this.match_id
                    }
                })
            }
        },
        // 获取赛事
        getMatcg() {
            axios.get('/pc/team/getGroup/' + this.match_id + '?sport_id=' + this.sport_id).then(res => {
                for (var a = 0; a < res.data.length; a++) {
                    if (res.data[a].matchGroupChild.length > 0) {
                        for (var b = 0; b < res.data[a].matchGroupChild.length; b++) {
                            // if (res.data[a].matchGroupChild[b].group_num.length > 0) {
                            this.kindList.push(res.data[a])
                            break
                            // }
                        }
                    }
                }
                for (var a = 0; a < this.kindList.length; a++) {
                    for (var p = 0; p < this.kindList[a].matchGroupChild.length; p++) {
                        // this.kindList[a].matchGroupChild[p].kind = this.kindList[a].matchGroupChild[p].group_num[0]
                        this.kindList[a].matchGroupChild[p].selectIndex = -1;
                        if (this.kindList[a].matchGroupChild[p].sign != '') {
                            this.kindList[a].selectIndex = p
                            // this.kindList[a].matchGroupChild[p].kind = this.kindList[a].matchGroupChild[p].sign.group_index
                        }
                    }
                }
            })
        },
        // getMatcg() {
        //     axios.get('/pc/team/getGroup/' + this.match_id + '?sport_id=' + this.sport_id).then(res => {
        //         for (var a = 0; a < res.data.length; a++) {
        //             if (res.data[a].matchGroupChild.length > 0) {
        //                 for (var b = 0; b < res.data[a].matchGroupChild.length; b++) {
        //                     if (res.data[a].matchGroupChild[b].group_num.length > 0) {
        //                         this.kindList.push(res.data[a])
        //                         break
        //                     }
        //                 }
        //             }
        //         }
        //         for (var a = 0; a < this.kindList.length; a++) {
        //             for (var p = 0; p < this.kindList[a].matchGroupChild.length; p++) {
        //                 this.kindList[a].matchGroupChild[p].kind = this.kindList[a].matchGroupChild[p].group_num[0]
        //                 this.kindList[a].matchGroupChild[p].selectIndex = -1;
        //                 if (this.kindList[a].matchGroupChild[p].sign != '') {
        //                     this.kindList[a].selectIndex = p
        //                     this.kindList[a].matchGroupChild[p].kind = this.kindList[a].matchGroupChild[p].sign.group_index
        //                 }
        //             }
        //         }
        //     })
        // },
        choiceUser(index) {
            this.noList = []
            this.actUser = index
            this.sport_id = this.userList[index].id
            console.log(this.userList[this.actUser])
            this.noList.push(this.userList[this.actUser])
            this.kindList = []
            this.getMatcg()
        },
        // 单人赛事
        choiceKind(type, key, index) {
            this.actKey = key
            this.actIndex = index
            if (index == this.kindList[key].selectIndex) {
                this.deleteApply()
            } else {
                this.actKind = index
                let stationIndex = this.kindList[key];
                stationIndex.selectIndex = index;
                this.$set(this.kindList, key, stationIndex);
                this.applytype = 'personal'
                this.apply(key, index)
            }

        },
        apply() {
            let key = this.actKey
            let index = this.actIndex
            let list = this.kindList
            let group_id = []
            let group_child_id = ''
            let rule_type = ''
            let group_child_level_id = ''
            let sport_id = []
            if (this.applytype == 'personal') {
                sport_id.push(this.userList[this.actUser].id)
            } else {
                sport_id.push(this.noList[0].id)
                for (var a = 0; a < this.rightList.length; a++) {
                    sport_id.push(this.rightList[a].id)
                }
                console.log(sport_id)
            }
            group_id = list[key].id
            group_child_id = list[key].matchGroupChild[index].child_id
            rule_type = list[key].rule_type
            group_child_level_id = list[key].matchGroupChild[index].id
            axios.post('/pc/team/matchSignUp/' + this.match_id, {
                sport_id: sport_id.join(','),
                group_id: group_id,
                group_child_id: group_child_id,
                rule_type: rule_type,
                group_child_level_id: group_child_level_id,
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.actKind = index
                    let stationIndex = this.kindList[key];
                    stationIndex.selectIndex = index;
                    this.$set(this.kindList, key, stationIndex);
                    this.applydialogFormVisible = false
                    this.getList()
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
            })
            // let add = []
            // let list = this.kindList
            // for (var a = 0; a < list.length; a++) {
            //     if (list[a].selectIndex >= 0) {
            //         add.push({
            //             group_id: list[a].id,
            //             group_child_id: list[a].matchGroupChild[list[a].selectIndex].id,
            //             group_index: list[a].matchGroupChild[list[a].selectIndex].kind,
            //             rule_type: list[a].rule_type
            //         })
            //     }
            // }
            // axios.post('/pc/team/matchSignUp/' + this.match_id, {
            //     project: JSON.stringify(add),
            //     sport_id: this.userList[this.actUser].id
            // }).then(res => {
            //     if (res.status == 200) {
            //         this.$message({
            //             message: '保存成功',
            //             type: 'success'
            //         })
            //     } else {
            //         this.$message({
            //             message: res.message,
            //             type: 'error'
            //         })
            //     }
            // })
        },
        netxStep() {
            this.$router.push({
                name: 'applylist',
                query: {
                    id: this.match_id,
                    type: this.type
                }
            })

        },
        uploadFile() {
            this.$refs.myfile.dispatchEvent(new MouseEvent('click'))
        },
        fileChange(e) {
            let myfile = this.$refs.myfile;
            let files = myfile.files;
            let file = files[0];
            let formData = new FormData();
            formData.append("file", file);
            console.log(file)
            axios.post('/pc/team/importSport', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(res => {
                    if (res.status == 200) {
                        this.xlsList = res.data
                        this.$router.push({
                            name: 'playerlist',
                            query: {
                                list: JSON.stringify(this.xlsList),
                                id: this.match_id
                            }
                        })
                    } else {
                        this.$message({
                            message: res.message,
                            type: 'error'
                        })
                    }

                })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.cloneList = []
            this.getPlayer()
        },
        getRowKeys(row) {
            return row.id
        },
        addPlayer() {
            this.$router.push({
                name: 'playerapply',
                query: {
                    id: this.match_id,

                    type: this.type
                }
            })
        },
        getList() {
            axios.get('/pc/team/saveData/' + this.match_id).then(res => {
                this.playerList_apply = res.data
                let a = 1;
                for (var p = 0; p < this.playerList_apply.length; p++) {
                    if (this.playerList_apply[p].count == 1) {
                        this.playerList_apply[p].z_index = a
                        a += 1
                    } else {
                        this.playerList_apply[p].z_index = a
                        a += 1
                        p += this.playerList_apply[p].count - 1
                    }
                    this.applytotal = a - 1
                }
            })
        },
        join({ row, column, rowIndex, columnIndex }) {
            if (columnIndex !== 2) {
                if (row.count > 1) {
                    if (rowIndex == 0) {
                        return {
                            rowspan: row.count,
                            colspan: 1
                        };
                    } else {
                        if (row.sport.name != this.playerList_apply[rowIndex - 1].sport.name) {
                            return {
                                rowspan: row.count,
                                colspan: 1
                            };
                        } else {
                            return {
                                rowspan: 0,
                                colspan: 0
                            };
                        }
                    }

                }
            }
        },
        lastStep() {
            this.$router.go(-1)
        },
        gotop() {
            document.documentElement.scrollTop = document.body.scrollTop = 0;

        },
        handleEdit(index, row) {
            let list = this.userList
            let id = []
            if (list.length == 0) {
                this.userList.push({
                    id: row.sport.id,
                    name: row.sport.name,
                    sex: row.sport.sex
                })
                this.actUser = this.userList.length - 1
                this.choiceUser(this.actUser)
                this.gotop()
            } else {
                for (var a = 0; a < list.length; a++) {
                    if (list[a].id == row.id) {
                        this.actUser = a
                        this.choiceUser(a)
                    }
                }
                if (list.findIndex(item => item.id === row.id) !== -1) {
                } else {
                    this.userList.push({
                        id: row.sport.id,
                        name: row.sport.name,
                        sex: row.sport.sex
                    })
                    this.actUser = this.userList.length - 1
                }
                this.choiceUser(this.actUser)
                this.gotop()
            }
        },
        handleDelete(index, row) {
            // 设置类似于console类型的功能
            this.$confirm("是否删除该条记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    axios.delete('/pc/team/delSaveData/' + row.sport_id + '/' + this.match_id).then(res => {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                        this.getList()
                        this.choiceUser(this.actUser)
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
    }
};
</script>

<style scoped>
.elrow{display: flex;flex-wrap: wrap;}
#playersapply {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
    position: absolute;
}
.main-box {
    width: 90%;
    height: auto;
    min-height: 860px;
    background-color: white;
    /* margin-left: 360px; */
    margin: 0 auto;
    margin-top: 47px;
    border-radius: 15px;
    position: relative;
}
.main-title {
    height: 80px;
    width: 100%;
    border-bottom: 2px solid #f1f1f1;
    /* background-color: aqua; */
    display: flex;
    position: relative;
}
.main-title p {
    line-height: 80px;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    margin-left: 61px;
}
.main-title button {
    width: 121px;
    height: 36px;
    background-color: #ff8f3a;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    margin-top: 25px;
}
.title-btn1 {
    position: absolute;
    right: 200px;
}
.title-btn {
    position: absolute;
    right: 66px;
}
.all-box {
    width: 90%;
    height: auto;
    /* background-color: aquamarine; */
    margin-left: 60px;
    background-color: white;
    position: relative;
}
.user-box {
    width: 100%;
    height: 241px;
    background-color: white;
    border: 2px solid #ededed;
    border-radius: 5px;
    margin-top: 37px;
}
.inside {
    width: 90%;
    height: 156px;
    margin-top: 24px;
    margin-left: 19px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
}
.userbtn {
    background-color: white;
}
.user-btn {
    width: 121px;
    height: 36px;
    background: #f7f7f7;
    border-radius: 5px;
    display: flex;
    margin-left: 12px;
}
.actuser {
    background: #fff8e5;
    color: #ff8f3a !important;
}
.user-name {
    width: 96px;
    line-height: 36px;
    color: #9a9a9a;
    border-radius: 5px;
}
.user-btn button {
    border: none;
    background: transparent;
    color: #9a9a9a;
}
.userbtn {
    text-align: right;
    margin-right: 31px;
}
.userbtn button {
    width: 121px;
    height: 36px;
    background: transparent;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-weight: bold;
    color: #ff8f3a;
    font-size: 12px;
}
.compet {
    width: 90%;
    height: auto;
    margin-top: 36px;
    background-color: white;
}
.kind {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    background-color: white;
}
.a {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    background-color: white;
}
.kindtitle {
    padding-left: 30px;
    width: 30px;
    line-height: 30px;
    border-left: 9px solid #ff8f3a;
    font-weight: bold;
    color: #000000;
    font-size: 20px;
    width: 100%;
    margin-bottom: 20px;
}
.kindlist {
    /* width: 121px; */
    height: 36px;
    background: #f7f7f7;
    border-radius: 5px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    color: #929292;
    font-size: 14px;
    margin-left: 15px;
    margin-top: 12px;
    padding: 0 20px;
}
.kindlist2 {
    display: flex;
    /* width: 241px; */
    padding: 0 20px;
    height: 36px;
    background: #f7f7f7;
    border-radius: 5px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    color: #929292;
    font-size: 14px;
    margin-left: 15px;
    margin-top: 12px;
    position: relative;
}
.kindlist2 span {
    text-align: center;
    width: 100%;
}
.select {
    position: absolute;
    right: 7px;
    float: right;
    width: 66px;
    height: 26px;
    line-height: 36px;
}
.actkind {
    background: #fff8e5;
    color: #ff8f3a !important;
}
.header {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #f1f1f1;
}
.addtype {
    text-align: left;
}
.add-title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-top: 34px;
}
.typelist {
    height: 159px;
    display: flex;
}
.typetab {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    text-align: center;
    line-height: 36px;
    background-color: #f7f7f7;
    color: #929292;
    font-weight: bold;
    margin-top: 34px;
}
.typetab2 {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    text-align: center;
    line-height: 36px;
    background-color: white;
    color: #ff8f3a;
    font-weight: bold;
    margin-top: 34px;
    border: 2px solid #ff8f3a;
}
.ok-btn {
    height: 160px;
    position: relative;
}
.btn-ok {
    position: absolute;
    bottom: 0;
    width: 121px;
    height: 36px;
    background: #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    border: none;
}
.a {
    color: #ff8f3a;
    text-decoration: none;
    margin-left: 30px;
}
.typetab:nth-child(2) {
    margin-left: 12px;
}
.typetab2:nth-child(2) {
    margin-left: 12px;
}
.actadd {
    background-color: #fff8e5;
    color: #ff8f3a;
}
.actmb {
    background-color: #ff8f3a;
    color: white;
}
.addbtn {
    width: 364px;
    height: 36px;
    background: #ff8f3a;
    border-radius: 5px;
    font-weight: bold;
    color: #ffffff;
    font-size: 12px;
    border: none;
    margin-top: 20px;
}
.searchbtn {
    position: absolute;
    right: 10px;
}
/* .transfer {
    text-align: left;
}
.transfer >>> .el-button {
    vertical-align: baseline;
}
.transfer >>> .el-transfer-panel {
    width: 350px;
    height: 400px;
}
.transfer >>> .el-transfer-panel__list {
    height: 400px;
} */
.transfer {
    width: 100%;
    height: 400px;
    display: flex;
}
.left {
    width: 40%;
    height: 400px;
    border: #aaa 1px solid;
    border-radius: 5px 5px 0 0;
}
.left-header {
    height: 30px;
    width: 100%;
    line-height: 30px;
    background-color: #ccc;
    font-size: 15px;
    text-align: left;
    border-radius: 5px 5px 0 0;
}
.left-header span {
    margin-left: 20px;
}
.left-main {
    margin: 0;
    padding: 6px 0;
    /* list-style: none; */
    height: 370px;
    overflow: auto;
    box-sizing: border-box;
}
.left-line {
    width: 80%;
    text-align: left;
    height: 40px;
    line-height: 40px;
}
.dan1 >>> .el-radio:last-child {
    margin-right: 30px;
}
.dan1 {
    width: 100%;
    height: 40px;
}
.dan {
    width: 100%;
}
.dan >>> .el-radio:last-child {
    margin-right: 30px;
}
.mid {
    width: 20%;
    line-height: 400px;
    font-size: 30px;
    color: blue;
}
.right {
    width: 40%;
    height: 400px;
    border-radius: 5px 5px 0 0;
    border: #aaa 1px solid;
}
.right-header {
    height: 30px;
    width: 100%;
    line-height: 30px;
    background-color: #ccc;
    font-size: 15px;
    text-align: left;
    border-radius: 5px 5px 0 0;
}
.right-header span {
    margin-left: 20px;
}
.right-main {
    margin: 0;
    padding: 6px 0;
    /* list-style: none; */
    height: 370px;
    overflow: auto;
    box-sizing: border-box;
}
.right-line {
    width: 80%;
    text-align: left;
    height: 40px;
    line-height: 40px;
}
.b-btn {
    width: 100%;
    text-align: right;
    height: 60px;
    line-height: 60px;
    margin-top: 20px;
}
.del {
    float: right;
}
.applyed {
    width: 90%;
    height: auto;
    background-color: white;
    /* margin-left: 360px; */
    margin: 0 auto;
    margin-top: 47px;
    border-radius: 15px;
}
.team-msg {
    width: 90%;
    min-height: 200px;
    margin: 45px 0 0 31px;
    border-radius: 5px;
}
.team-msg p {
    text-align: left;
    font-weight: bold;
    line-height: 80px;
    color: #000000;
    font-size: 18px;
    margin-bottom: 28px;
}
.team-btn {
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 36px;
    float: right;
    margin: 41px 33px 0 0;
}
.btn1 {
    text-align: left;
}
.btn1 button {
    width: 121px;
    height: 36px;
    background-color: transparent;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    margin-top: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #ff8f3a;
}
.pagetab {
    margin-top: 20px;
    float: right;
    margin-right: 30px;
}
.btn-add {
    height: 36px;
    text-align: right;
    padding: 50px 0;
}
.btn-add button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
    margin-right: 64px;
}
.el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
}
.el-table__fixed-right-patch {
    background-color: #f5f7fa !important;
}
</style>
