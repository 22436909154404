import { axios } from "@/utils/request";


export function login(data) {
    return axios({
        url: '/pc/auth/login',
        method: 'post',
        data
    })
}
export function registe(data) {
    return axios({
        url: '/pc/auth/register',
        method: 'post',
        data
    })
}
export function frogetPwd(data) {
    return axios({
        url: '/pc/auth/forget',
        method: 'post',
        data
    })
}
export function getcode(data) {
    return axios({
        url: '/api/auth/verify',
        method: 'post',
        data
    })
}
