import Vue from "vue";
import axios from "axios";
import store from "@/store";
import { ACCESS_TOKEN, AUTHORIZATION } from '@/config/constants'
import router from '@/router';

const service = axios.create({
    baseURL: 'https://sport.yiti-china.com/', // 公共请求地址
    // baseURL: window.location.protocol + '//' + window.location.hostname, // 公共请求地址

    // timeout: 6000, // 请求超时时间
})

// // 请求拦截器
service.interceptors.request.use((config) => {
    // Vue.ls本地储存token
    const token = localStorage.getItem('Authorization')
    if (token) {
        // 添加token请求头
        config.headers[ACCESS_TOKEN] = token
    }
    return config
}, error => Promise.reject(error))
service.interceptors.response.use(
    response => {
        let res = response.data;
        if(res.message == '请登录'){
          router.replace({
              path: '/login',
              query: {
                  type: 0
              }
          })
          return;
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        // console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

// service.interceptors.response.use((response) => {
//     const result = response.data
//     // 根据请求状态码，做一些公共的处理
//     if (result.code !== 200) {
//         // 401: 未授权，请重新登录
//         if (result.code === 401) {
//             // 登出操作: 我这里是写在vuex里的异步方法，实际情况根据自己项目写
//             store.dispatch('Logout').then(() => {
//                 setTimeout(() => {
//                     window.location.reload()
//                 }, 1000)
//             })
//         }
//       response.data
//     }
//     (error) => {
//         // 响应异常处理
//         Message.error('Network Error')
//         return Promise.reject(error)
//     }
// })

export { service as axios }
