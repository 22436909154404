<template>
    <div id="noteam" :style="{height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="team">
            <div class="team-head">
                <p class="team-title">确认信息</p>
            </div>
            <div class="team-msg">
                <div class="team-box">
                    <div class="team-left">
                        <img :src="teamList.logo" alt="">
                    </div>
                    <div class="team-right">
                        <p class="msg-name">{{teamList.team_name}}（{{teamList.team_short_name}}）</p>
                        <p class="msg-type">团队类型:{{teamList.team_type}}</p>
                        <p class="msg-area">所在地区:{{teamList.in_city_c}}</p>
                        <p class="msg-add">详细地址:{{teamList.detail_address}}</p>
                        <p class="msg-userlist">
                            <span class="msg-user">联系人:{{teamList.contact}}</span>
                            <span class="msg-tel">联系方式:{{teamList.mobile}}</span>
                        </p>
                    </div>
                </div>
                <button class="team-btn" @click="changeIt">编辑</button>
            </div>
            <div class="btn">
                <button class="btn-up" @click="lastStep">上一步</button>
                <button class="btn-down" @click="netxStep">下一步</button>
            </div>
        </div>
    </div>
</template>

<script>
import { getTeam } from '@/api/compet'
import { axios } from '@/utils/request'
import header from '@/components/header.vue'
export default {
    name: "noteam",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            teamList: {
            },
            match_id: ''
        };
    },
    created() {
        const { id } = this.$route.query
        this.match_id = id
        this.getTeamInfo()
    },
    mounted() {
        this.check()
    },
    components: {
        'my-header': header
    },
    methods: {
        check() {
            axios.get('pc/team/orderstatus/' + this.match_id).then(res => {
                if (res.data == 0) {
                    this.$message({
                        message: '该赛事已报名成功，如需修改请联系管理员',
                        type: 'error'
                    })
                    this.$router.push({
                        path: '/detail',
                        query: {
                            id: this.match_id
                        }
                    })
                }
            })
        },
        getTeamInfo() {
            getTeam().then(res => {
                if (res.status == 200) {
                    if (res.data.team_name) {
                        this.teamList = res.data
                    } else {
                        this.$router.push({
                            path: '/noteam',
                            query: {
                                id: this.match_id,
                                key: 0
                            },
                        })
                    }
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                    this.$router.push({
                        path: '/login',
                        query: {
                            type: 0
                        },
                    })
                }

            })
        },
        changeIt() {
            this.$router.push({
                path: '/noteam',
                query: {
                    id: this.match_id,
                    key: 1
                },
            })
        },
        netxStep() {
            this.$router.push({
                path: '/haveleader',
                query: {
                    id: this.match_id
                },
            })
        },
        lastStep() {
            this.$router.push({
                path: '/detail',
                query: {
                    id: this.match_id
                },
            })
        }
    }
};
</script>

<style scoped>
#noteam {
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
    position: absolute;
}
.team {
    width: 90%;
    height: 800px;
    background-color: white;
    /* margin-left: 360px; */
    margin: 0 auto;
    margin-top: 45px;
    padding-bottom: 30px;
    border-radius: 15px;
    position: relative;
}
.team-head {
    width: 100%;
    height: 80px;
    border-bottom: 1px #f1f1f1 solid;
}
.team-title {
    text-align: left;
    font-size: 20px;
    font-weight: 550;
    color: #000000;
    line-height: 80px;
    margin-left: 59px;
}
.team-msg {
    width: 90%;
    height: 328px;
    background-color: #fff8e5;
    margin: 50px 0 0 102px;
    border-radius: 5px;
}
.team-box {
    display: flex;
}
.team-left {
    width: 317px;
    height: 100%;
}
.team-left img {
    width: 169px;
    height: 169px;
    border-radius: 50%;
    /* text-align: left; */
    margin: 47px 20px 0 0;
}
.team-right {
    text-align: left;
    margin-top: 44px;
    font-weight: bold;
}
.msg-name {
    font-size: 18px;
    font-weight: 550;
    color: #000;
    line-height: 18px;
}
.msg-type {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 26px;
}
.msg-area {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 26px;
}
.msg-add {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 27px;
}
.msg-userlist {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 27px;
}
.msg-tel {
    margin-left: 26px;
}
.team-btn {
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 36px;
    float: right;
    margin: 41px 33px 0 0;
    background-color: white;
}
.btn {
    /* width: 100%; */
    /* height: 36px; */
    /* background-color: #686868; */
    /* text-align: right; */
    /* margin-top: 380px; */
    position: absolute;
    right: 00px;
    bottom: 30px;
}
.btn button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
    margin-right: 64px;
}
</style>
