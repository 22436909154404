<template>
    <div id="teampage">
        <div class="head">
            <span class="head-title">团队主页</span>
            <button class="head-btn" @click='toChange'>编辑</button>
        </div>
        <div class="teampage-mian">
            <img :src="teamList ? teamList.logo : ''" alt="" width="137px" height="137px">
            <div class="p0">
                <p class="p3">团队名称：{{teamList ? teamList.team_name : ''}}</p>
                <span>团队简称：{{teamList ? teamList.team_short_name : ''}}</span>
            </div>
            <div class="p1">
                <p class="p3"> 团队类型：{{teamList ? teamList.team_type : ''}}</p>
                <span>所在地区：{{teamList ? teamList.in_area : ''}}</span>
            </div>
            <div class="p2">详细地址：{{teamList ? teamList.detail_address : ''}}</div>
            <div class="p1">
                <p class="p3">联系人：{{teamList ? teamList.contact : ''}}</p>
                <span>联系方式：{{teamList ? teamList.mobile : ''}}</span>
            </div>
            <div class="p1">
                <p class="p3">中跆协会员号：{{teamList ? teamList.z_member_number : ''}}</p>
                <span>省跆协会员号：{{teamList ? teamList.s_member_number : ''}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getuser } from '@/api/user'
export default {
    name: "teampage",
    data() {
        return {
            teamList: {
            }
        };
    },
    created() {
        this.getUserInfo()
    },
    methods: {
        toChange() {
            this.$router.push({
                name: 'noteam',
                query: {
                    id: 0,
                    key: 2,
                }
            })
        },
        getUserInfo() {
            getuser().then(res => {
                this.teamList = res.data
            })
        },
    }
};
</script>

<style scoped>
#teampage {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.head-btn {
    position: absolute;
    right: 36px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.teampage-mian {
    padding-left: 78px;
    box-sizing: border-box;
    margin-top: 54px;
    text-align: left;
}
.teampage-mian img {
    border-radius: 50%;
}
.p0 {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 52px;
    font-weight: bold;
    display: flex;
}
.p1 {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 29px;
    font-weight: bold;
    display: flex;
}
.p2 {
    font-size: 14px;
    color: #686868;
    line-height: 14px;
    margin-top: 29px;
    font-weight: bold;
    width: 400px;
}
.p3 {
    width: 250px;
}
</style>
