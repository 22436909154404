<template>
    <div id="applydetail">
        <div class="head">
            <span class="head-title">报项中心</span>
            <button class="head-btn" @click="getAgain" v-if="type==1">编辑</button>
        </div>
        <div class="applydetail-mian">
            <p class="title">{{competList.name}}</p>
            <p class="title2">比赛开始时间：{{competList.date}}</p>
            <div class="table">
                <el-table border :data="applyDate" style="width: 96%;margin-top:50px" algin='left' :header-cell-style="{background:'#F8F9FA'}" height="580">
                    <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                    <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                    <el-table-column label="性别" align="center" width="55">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sport.sex==0">男</span>
                            <span v-else>女</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                    <el-table-column prop="sport.team_name" label="参赛机构" align="center"></el-table-column>
                    <el-table-column label="个人项目" align="center">
                        <template slot-scope="scope">
                            <div v-for="(item,index) in scope.row.sign" :key="index">
                                <span v-if="item.group.rule_type == 'personal'">{{item.group.name}},{{item.groupChild.name}},{{item.groupLevel.level_name}}</span>
                                <span v-if="item.group.rule_type == 'double'">{{item.group.name}},{{item.groupChild.name}},{{item.groupLevel.level_name}}{{item.group_index}}组</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="团体项目" align="center">
                        <template slot-scope="scope">
                            <div v-for="(item,index) in scope.row.sign" :key="index">
                                <span v-if="item.group.rule_type == 'team'">{{item.group.name}},{{item.groupChild.name}}{{item.group_index}}组</span>
                                <span v-if="item.group.rule_type == 'dance'">{{item.group.name}},{{item.groupChild.name}}{{item.group_index}}组</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request';
export default {
    name: "applydetail",
    data() {
        return {
            competList: {
                name: "福建省跆拳道锦标赛",
                date: '2022-20-22'
            },
            id: '',
            applyDate: [
            ],
            type: 0
        };
    },
    created() {
        const { id, title, date, type } = this.$route.query
        this.id = id
        this.competList.name = title
        this.competList.date = date
        this.type = type
        this.getmatchDetail(id)
    },
    methods: {
        changTab(index) {
            this.actTab = index
        },
        getAgain() {
            this.$router.push({
                path: '/playerapply',
                query: {
                    id: this.id
                }
            })
            // axios.get('/pc/order/getItemDataChild?match_id=' + this.id).then(res => {
            //     this.applyDate = res.data
            //     this.$message({
            //         message: '刷新成功',
            //         type: 'success'
            //     })
            // })
        },
        getmatchDetail(id) {
            axios.get('/pc/order/getItemDataChild?match_id=' + id).then(res => {
                this.applyDate = res.data
            })
        }
    }
};
</script>

<style scoped>
#applydetail {
    background-color: white;
    width: 90%;
    height: 100%;
}
.head {
    width: 100%;
    height: 66px;
    border-bottom: 2px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
    margin: 26px 0 0 64px;
}
.head-btn {
    position: absolute;
    right: 36px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.applydetail-mian {
    margin-left: 46px;
    margin-top: 31px;
}
.title {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #2e2e2e;
    margin-top: 62px;
}
.title2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: #929292;
    margin-top: 27px;
}
</style>
