<template>
    <div id="login">
        <div class="login">
            <div :style="{ height: screenHeight + 'px' }" class='odiv'>
                <!-- <img src="../../assets/img/3.jpeg" alt="" width="100%" height="100%"> -->
            </div>
            <div class="login-form">
                <div class="loginin" v-if="this.type == 0">
                    <p class="title">登录</p>
                    <el-form class="form1" label-position="top" :model="loginFrom">
                        <el-form-item label="手机号">
                            <!-- <el-input v-model="loginFrom.tel" ></el-input> -->
                            <input type="text" v-model="loginFrom.tel">
                        </el-form-item>
                        <el-form-item label="密码">
                            <input type="password" v-model="loginFrom.passwd" @keyup.enter='loginIn'>
                        </el-form-item>
                    </el-form>
                    <button type="button" class="btn" @click="loginIn">登录</button>
                    <div class="choose">
                        <span class="register"><a @click="wantReg">注册账号</a></span>
                        <span class="forget"><a @click="wantFor">忘记密码?</a></span>
                    </div>
                    <p class="tip">2006-2021 LONGMIAO</p>
                </div>
                <div class="loginin" v-if="this.type == 1">
                    <p class="title">注册</p>
                    <el-form class="form1" label-position="top" :model="loginFrom">
                        <el-form-item label="手机号">
                            <input type="text" v-model="loginFrom.tel">
                        </el-form-item>
                        <el-form-item label="验证码">
                            <input type="text" style="width:70%" v-model="loginFrom.key">
                            <a @click="getCode">{{getText}}</a>
                        </el-form-item>
                        <el-form-item label="密码">
                            <input type="password" v-model="loginFrom.passwd">
                        </el-form-item>
                    </el-form>
                    <button type="button" class="btn" @click="registeIn">注册并登录</button>
                    <div class="choose1">
                        <span class="havenumber"><a @click="haveIt">已有帐号</a></span>
                    </div>
                    <p class="tip">2006-2021 LONGMIAO</p>
                </div>
                <div class="loginin" v-if="this.type == 2">
                    <p class="title">忘记密码</p>
                    <el-form class="form1" label-position="top" :model="loginFrom">
                        <el-form-item label="手机号">
                            <input type="text" v-model="loginFrom.tel">
                        </el-form-item>
                        <el-form-item label="验证码">
                            <input type="text" style="width:70%" v-model="loginFrom.key">
                            <a @click="getCode">{{getText}}</a>
                        </el-form-item>
                        <el-form-item label="新密码">
                            <input type="password" v-model="loginFrom.newpasswd">
                        </el-form-item>
                    </el-form>
                    <button type="button" class="btn" @click="forgetPwd">提交</button>
                    <p class="tip">2006-2021 LONGMIAO</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { login, registe, frogetPwd, getcode } from '@/api/login.js'
export default {
    name: "login",
    data() {
        return {
            type: 0,
            loginFrom: {
                tel: '',
                passwd: '',
                key: '',
                newpasswd: ''
            },
            input: "",
            getText: '获取验证码',
            totalTime: 60,
            screenHeight: document.body.clientHeight,
            codeType: ''
        };
    },
    watch: {
    },
    mounted() {
        const { type } = this.$route.query
        // 0登录 1注册 2忘记
        this.type = type
    },
    created() {
    },
    methods: {
        // 忘记密码提交
        forgetPwd() {
            frogetPwd({
                code: this.loginFrom.key,
                mobile: this.loginFrom.tel,
                password: this.loginFrom.newpasswd,
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '修改成功去登录',
                        type: "success",
                    });
                    this.type = 0
                    this.loginFrom = {}
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }

            })
        },
        // 注册提交
        registeIn() {
            registe({
                code: this.loginFrom.key,
                mobile: this.loginFrom.tel,
                password: this.loginFrom.passwd,
            }).then(res => {
                if (res.status == 200) {
                    this.$store.commit("changeLogin", res.data.token);
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.$router.push({
                        path: '/'
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }

            })
        },
        // 登录提交
        loginIn() {
            if (this.loginFrom.tel == '' || this.loginFrom.passwd == '') {
                this.$message({
                    message: '请输入手机号密码',
                    type: "error",
                });
                return
            }
            login({
                mobile: this.loginFrom.tel,
                password: this.loginFrom.passwd
            }).then(res => {
                if (res.status == 200) {
                    this.$store.commit("changeLogin", res.data.token);
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.$router.push({
                        path: '/'
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }

            })
        },
        // 切换为注册
        wantReg() {
            this.type = 1
            this.loginFrom = {}
        },
        // 切换忘记密码
        wantFor() {
            this.type = 2
            this.loginFrom = {}
        },
        // 切换为登录
        haveIt() {
            this.type = 0
            this.loginFrom = {}
        },
        // 获取验证码请求
        getCode() {
            var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if (!myreg.test(this.loginFrom.tel)) {
                this.$message({
                    message: '手机号格式不正确',
                    type: "error",
                });
                return false;
            }
            if (this.type == 1) {
                this.codeType = 'register'
            } else if (this.type == 2) {
                this.codeType = 'forget '
            }
            getcode({
                phone: this.loginFrom.tel,
                type: this.codeType
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '发送成功',
                        type: "success",
                    });
                    this.haveCode()
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
            })

        },
        // 获取验证码点击
        haveCode() {
            if (this.getText == '重新发送验证码' || this.getText == '获取验证码') {
                // this.countDown = true;//点击之后设置按钮不可取
                this.getText = this.totalTime + "s后重新发送";//按钮内文本
                let clock = window.setInterval(() => {
                    this.totalTime--;
                    this.getText = this.totalTime + "s后重新发送";
                    if (this.totalTime < 0) {
                        window.clearInterval(clock);
                        this.getText = "重新发送验证码";
                        this.totalTime = 60;
                        this.countDown = false; //这里重新开启
                    }
                }, 1000);
            }
        },
    }
};
</script>

<style scoped>
/* 登录 */
.login {
    height: auto;
    display: flex;
}
/* 左侧图片 */
.odiv {
    height: 100vh;
    /* width: 100%; */
    width: 50%;
    background: url(../../assets/img/000.jpg) no-repeat;
    background-size: 100% 100%;
}
.loginin {
    width: 100%;
    /* padding-left: 128px; */
    box-sizing: border-box;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* 右侧表单 */
.login-form {
    width: 50%;
    height: 100%;
}
.login-form {
    width: 50%;
    height: 100%;
}
.title {
    font-size: 48px;
    color: #000;
    font-weight: bold;
}
.form1 {
    text-align: left;
    color: #506499;
    width: 60%;
    margin-top: 50px;
}
.form1 input {
    width: 100%;
    border: none;
    border-bottom: 3px #ff8f3a solid;
    height: 30px;
    outline: none;
    font-size: 20px;
}
.form1 a {
    text-decoration: none;
    color: #ff8f3a;
    font-size: 18px;
    height: 34px;
    line-height: 34px;
    text-align: right;
    width: 30%;
    float: right;
    border-bottom: 3px #ff8f3a solid;
}
.btn {
    width: 451px;
    height: 58px;
    background-color: #ff8f3a;
    color: white;
    font-size: 20px;
    line-height: 58px;
    border-radius: 5px;
    border: none;
    margin-top: 30px;
}
/* 选项 */
.choose {
    margin-top: 35px;
    width: 451px;
    font-size: 18px;
}
.choose a {
    text-decoration: none;
    color: #686868;
}
.register {
    float: left;
}
.forget {
    float: right;
}
.choose1 {
    margin-top: 35px;
    width: 451px;
    font-size: 18px;
}
.choose1 a {
    text-decoration: none;
    color: #686868;
}
/* 提示 */
.tip {
    font-size: 16px;
    color: #b2b2b2;
    text-align: left;
    position: absolute;
    margin-left: 140px;
    bottom: 30px;
}
</style>
