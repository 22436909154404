<template>
    <div id="haveleader" :style="{height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="main-box">
            <div class="main-title">
                <p>选择教练、领队</p>
                <button class="title-btn" @click="addLeader">添加人员</button>
            </div>
            <div class="main-leader">
                <div class="leader-box">
                    <div class="choice-box">
                        <p class="box-title">教练:</p>
                        <div class="box">
                            <p v-if="coachList.length==0">暂无该角色成员可以选择</p>
                            <div class="box-option" v-for="(coach,index) in coachList" :key="index" @click="choiceCoach(index)" :class="{'acttab':actCoach === index}">
                                <img :src="coach.avatar" alt="" width="51px" height="51px">
                                <span>{{coach.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="choice-box">
                        <p class="box-title">领队:</p>
                        <div class="box">
                            <p v-if="leaderList.length==0">暂无该角色成员可以选择</p>
                            <div class="box-option" v-for="(leader,index) in leaderList" :key="index" @click="choiceLeader(index)" :class="[ actLeader.indexOf(index) != -1 ? 'acttab' : 'none' ]">
                                <img :src="leader.avatar" alt="" width="51px" height="51px">
                                <span>{{leader.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="choice-box">
                        <p class="box-title">其他:</p>
                        <div class="box">
                            <p v-if="otherList.length==0">暂无该角色成员可以选择</p>
                            <div v-else class="box-option" v-for="(other,index) in otherList" :key="index" @click="choiceOther(index)" :class="[ actOrder.indexOf(index) != -1 ? 'acttab' : 'none' ]">
                                <img :src="other.avatar" alt="" width="51px" height="51px">
                                <span>{{other.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn">
                        <button class="btn-up" @click="lastStep">上一步</button>
                        <button class="btn-down" @click="netxStep">下一步</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
import { getleaderlist } from '@/api/compet'
import header from '@/components/header.vue'
export default {
    name: "haveleader",
    data() {
        return {
            screenHeight: document.body.clientHeight,
            coachList: [
            ],
            leaderList: [
            ],
            otherList: [
            ],
            actCoach: 0,
            actLeader: [],
            actOrder: [],
            match_id: '',
        };
    },
    mounted() {
        this.check()
    },
    created() {
        const { id } = this.$route.query
        this.match_id = id
        this.getLeaderInfo()
    },
    components: {
        'my-header': header
    },
    methods: {
        check() {
            axios.get('pc/team/orderstatus/' + this.match_id).then(res => {
                if (res.data == 0) {
                    this.$message({
                        message: '该赛事已报名成功，如需修改请联系管理员',
                        type: 'error'
                    })
                    this.$router.push({
                        path: '/detail',
                        query: {
                            id: this.match_id
                        }
                    })
                }
            })
        },
        addLeader() {
            this.$router.push({
                path: '/noleader',
                query: {
                    id: this.match_id
                }
            })
        },
        getLeaderInfo() {
            getleaderlist().then(res => {
                // if (res.data.coach.length == 0 && res.data.leader.length == 0 && res.data.other.length == 0) {
                //     this.$router.push({
                //         path: '/noleader',
                //         query: {
                //             id: this.match_id
                //         }
                //     })
                // } else {
                this.coachList = res.data.coach
                this.leaderList = res.data.leader
                this.otherList = res.data.other
                this.getSelect()
                // }
            })
        },
        getSelect() {
            axios.get('/pc/team/getOtherPeople/' + this.match_id).then(res => {
                if (res.leader.length > 0) {
                    for (var a = 0; a < res.leader.length; a++) {
                        for (var b = 0; b < this.leaderList.length; b++) {
                            if (res.leader[a] == this.leaderList[b].id) {
                                this.actLeader.push(b)
                            }
                        }
                    }
                }
                if (res.other.length > 0) {
                    for (var a = 0; a < res.other.length; a++) {
                        for (var b = 0; b < this.otherList.length; b++) {

                            if (res.other[a] == this.otherList[b].id) {
                                this.actOrder.push(b)
                            }
                        }
                    }
                }
                if (res.coach != '') {
                    for (var b = 0; b < this.coachList.length; b++) {
                        if (res.coach == this.coachList[b].id) {
                            console.log(1)
                            this.actCoach = b
                        }
                    }
                }
                console.log(this.actLeader, this.actOrder, this.actCoach)
            })
        },
        netxStep() {
            let leaders = []
            let others = []
            if (this.actLeader.length > 0) {
                for (var a = 0; a < this.actLeader.length; a++) {
                    leaders.push(this.leaderList[this.actLeader[a]].id)
                }
            }
            if (this.actOrder.length > 0) {
                for (var a = 0; a < this.actOrder.length; a++) {
                    others.push(this.otherList[this.actOrder[a]].id)
                }
            }
            axios.post('/pc/team/addOtherPeople/' + this.match_id, {
                coach: this.coachList[this.actCoach].id,
                leader: leaders.join(','),
                other: others.join(','),
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: res.message,
                        type: "success",
                    });
                    this.$router.push({
                        path: '/playerapply',
                        query: {
                            id: this.match_id
                        }
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
            })
        },
        lastStep() {
            this.$router.push({
                path: '/haveteam',
                query: {
                    id: this.match_id
                }
            })
        },
        choiceCoach(index) {
            this.actCoach = index
        },
        choiceLeader(index) {
            const i = this.actLeader.findIndex(item => item == index)// 找到该元素在数组中的索引
            if (i !== -1) this.actLeader.splice(i, 1) // 该元素存在则干掉
            else this.actLeader.push(index) // 不存在添加
        },
        // choiceOther(index) {
        //     this.actOrder = index
        // },
        choiceOther(index) {
            const i = this.actOrder.findIndex(item => item == index)// 找到该元素在数组中的索引
            if (i !== -1) this.actOrder.splice(i, 1) // 该元素存在则干掉
            else this.actOrder.push(index) // 不存在添加
        },
    }
};
</script>

<style scoped>
#haveleader {
    height: auto;
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.main-box {
    width: 90%;
    margin:0 auto;
    height: 800px;
    background-color: white;
    /* margin-left: 360px; */
    margin-top: 47px;
    border-radius: 15px;
    position: relative;
}
.main-title {
    height: 80px;
    width: 100%;
    border-bottom: 2px solid #f1f1f1;
    /* background-color: aqua; */
    display: flex;
    position: relative;
}
.main-title p {
    line-height: 80px;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    margin-left: 61px;
}
.main-title button {
    width: 121px;
    height: 36px;
    background-color: #ff8f3a;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    margin-top: 25px;
    position: absolute;
    right: 66px;
}
.main-leader {
    height: auto;
}
.leader-box {
    width: 90%;
    height: 100%;
    margin-left: 5%;
    margin-top: 43px;
}
.choice-box {
    text-align: left;
}
.box-title {
    font-weight: bold;
    font-size: 18px;
    color: #000;
}
.box {
    display: flex;
    height: 165px;
    width: 100%;
}
.box p {
    text-align: center;
    margin-left: 460px;
    line-height: 165px;
    font-weight: bold;
    color: #bababa;
    font-size: 18px;
}
.box-option {
    display: flex;
    width: 269px;
    height: 65px;
    background-color: #f7f7f7;
    border: 2px solid #ededed;
    border-radius: 5px;
    line-height: 65px;
    margin-top: 50px;
    margin-right: 33px;
}
.box-option img {
    border-radius: 50%;
    margin: 7px 28px 0 21px;
}
.box-option p {
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
.acttab {
    border: 2px solid white;
    color: #ff8f3a;
    background-color: #fff8e5;
}
.btn {
    height: 36px;
    position: absolute;
    right: 0;
    bottom: 34px;
}
.btn button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
    margin-right: 64px;
}
</style>
