<template>
    <div id="playerslist" :style="{height: screenHeight + 'px' }">
        <my-header></my-header>
        <div class="team">
            <div class="team-head">
                <p class="team-title">运动员列表</p>
            </div>
            <div class="team-msg">
                <el-table :data="playerList" style="width: 100%" algin='center' :header-cell-style="{background:'#F8F9FA'}" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="50" align="center"></el-table-column>
                    <el-table-column label="头像 " align="center" width="100">
                        <template slot-scope="scope">
                            <div style="width:100%;height:50px;text-algin:center;" @click="getIndex(scope.$index)">
                                <el-upload class="avatar-uploader" :action=url :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                    <img v-if="scope.row.avatar" :src="scope.row.avatar" class="avatar" width="100%" height="100%">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <!-- <img style="text-algin:center;" :src="scope.row.avatar" width="100%" height="100%"> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" width="80" align="center"></el-table-column>
                    <el-table-column prop="nationality" label="国籍" width="80" align="center"></el-table-column>
                    <el-table-column prop="national" label="民族" width="80" align="center"></el-table-column>
                    <el-table-column prop="card_type" label="证件类型" width="120" align="center"></el-table-column>
                    <el-table-column prop="card_number" label="证件号码" width="" align="center"></el-table-column>
                    <el-table-column prop="sex" label="性别 " align="center" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sex==1?'男':'女' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="birthday" label="出生日期" width="120" align="center"></el-table-column>

                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="btn1">
                    <button @click="deleteMore">批量删除</button>
                </div>
                <div class="pagetab">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="8" layout="total,prev, pager, next, jumper" :total="total">
                    </el-pagination>
                    <div class="btn">
                        <button class="btn-up" @click="lastStep">取消</button>
                        <button class="btn-down" @click="netxStep">确定</button>
                    </div>
                </div>

            </div>

        </div>
        <el-dialog title="用户信息" :visible.sync="dialogFormVisible">
            <el-form :model="form" :label-position="labelPosition" label-width="80px" style="width:60%;margin-left: 20%;">
                <el-form-item label="姓名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="国籍">
                    <el-select v-model="form.nationality" placeholder="请选择" style="width:100%;" filterable>
                        <el-option v-for="item in countryList" :key="item.cname" :label="item.cname" :value="item.cname">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="民族">
                    <el-select v-model="form.national" placeholder="请选择" style="width:100%;">
                        <el-option v-for="item in nationList" :key="item.ethnic_name" :label="item.ethnic_name" :value="item.ethnic_name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件类型">
                    <el-select v-model="form.card_type" placeholder="请选择" style="width:100%;">
                        <el-option v-for="item in cardList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件号码">
                    <el-input v-model="form.card_number" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="form.sex" placeholder="请选择" style="width:100%;">
                        <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出生日期">
                    <el-date-picker v-model="form.birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width:100%;"></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="update">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getcountry, getnational, saveplayer } from '@/api/compet'
import header from '@/components/header.vue'
export default {
    name: "playerslist",
    data() {
        return {
            url: window.location.protocol + '//' + window.location.hostname + "/api/upload/image/file",
            // url: "https://sport.yiti-china.com/api/upload/image/file",
            screenHeight: document.body.clientHeight,
            playerList: [],
            form: {
                name: '',
                country: '',
                nation: '',
                card: '',
                card_number: '',
                sex: '',
                date: '',
                avatar: ''
            },
            countryList: [],
            nationList: [],
            cardList: [{ value: '身份证', label: '身份证' }, { value: '护照', label: '护照' }, { value: '其他', label: '其他' }],
            sexList: [{ value: 1, label: '男' }, { value: 2, label: '女' }],
            dialogFormVisible: false,
            labelPosition: 'right',
            currentPage: 1,
            list: [],
            actIndex: '',
            total: '',
            multipleSelection: [],
            delete: [],
            match_id: '',
            indexs: '',
        };
    },
    created() {
        this.getData()
        this.getNational()
        this.getCountry()
    },
    components: {
        'my-header': header
    },
    methods: {
        getIndex(index) {
            this.indexs = index
        },
        getData() {
            let { list, id } = this.$route.query
            let obj = list.replace("\"([^\"]*)\"", "$1");
            this.playerList = JSON.parse(obj)
            this.match_id = id
            console.log(this.match_id)
            // for (var a = 0; a < this.playerList.length; a++) {
            //     this.playerList[a].id = a
            // }
            this.total = this.playerList.length
        },
        lastStep() {
            this.$router.push({
                path: '/playerapply',
                query: {
                    id: this.match_id
                }
            })
        },
        netxStep() {
            for (var a = 0; a < this.playerList.length; a++) {
                if (this.playerList[a].avatar == '') {
                    this.$message({
                        type: "error",
                        message: '请为运动员上传头像'
                    });
                    return
                }
            }
            saveplayer({
                sport: JSON.stringify(this.playerList)
            }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        type: "success",
                        message: res.message
                    });
                    this.$router.push({
                        path: '/playerapply',
                        query: {
                            id: this.match_id
                        }
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    });
                }
            })
            // this.$router.push({
            //     path: '/haveleader'
            // })
        },
        getNational() {
            getnational().then(res => {
                this.nationList = res.data
            })
        },
        getCountry() {
            getcountry().then(res => {
                // this.countryList = res.data
                for (var a = 0; a < res.data.length; a++) {
                    this.countryList.push(res.data[a])
                }
            })
        },
        update() {
            this.playerList[this.actIndex] = this.form
            this.dialogFormVisible = false;
        },
        handleEdit(index, row) {
            // 将数据的index传递过来用于实现数据的回显
            this.form = this.playerList[index];
            this.actIndex = index;
            // 设置对话框的可见
            this.dialogFormVisible = true;
        },
        handleDelete(index, row) {
            // 设置类似于console类型的功能
            this.$confirm("是否确认删除所选角色?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                // 移除对应索引位置的数据，可以对row进行设置向后台请求删除数据
                this.playerList.splice(index, 1);
                this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                this.total = this.playerList.length
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除"
                });
            });
        },
        cancel() {
            // 取消的时候直接设置对话框不可见即可
            this.dialogFormVisible = false;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        deleteMore() {
            this.$confirm("永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                for (var a = 0; a < this.multipleSelection.length; a++) {
                    this.delete.push(this.multipleSelection[a].card_number)
                }
                for (var a = 0; a < this.delete.length; a++) {
                    for (var b = 0; b < this.playerList.length; b++) {
                        if (this.delete[a] == this.playerList[b].card_number) {
                            this.playerList.splice(b, 1);
                        }
                    }
                }
                this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                this.total = this.playerList.length
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除"
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('只能上传图片!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleAvatarSuccess(res, file) {
            this.playerList[this.indexs].avatar = res.data.path;
        },
    }
};
</script>

<style scoped>
#playerslist {
    position: absolute;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.team {
    width: 1200px;
    height: 830px;
    background-color: white;
    margin-left: 360px;
    margin-top: 45px;
    padding-bottom: 30px;
    border-radius: 15px;
}
.team-head {
    width: 100%;
    height: 80px;
    border-bottom: 1px #f1f1f1 solid;
}
.team-title {
    text-align: left;
    font-size: 20px;
    font-weight: 550;
    color: #000000;
    line-height: 80px;
    margin-left: 59px;
}
.team-msg {
    width: 1139px;
    height: 530px;
    margin: 45px 0 0 31px;
    border-radius: 5px;
    position: relative;
}
.team-btn {
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    line-height: 36px;
    float: right;
    margin: 41px 33px 0 0;
}
.btn1 {
    text-align: left;
}
.btn1 button {
    width: 121px;
    height: 36px;
    background-color: transparent;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    margin-top: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #ff8f3a;
}
.pagetab {
    margin-top: 20px;
    float: right;
    margin-right: 60px;
}
.btn {
    float: right;
    height: 36px;
    text-align: right;
    padding: 30px 0;
}
.btn button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
}
.avatar-uploader {
    border: 1px solid #ededed;
    background-color: #fffeff;
    width: 100%;
    height: 100%;
    line-height: 50px;
    /* margin-left: 97px; */
    border-radius: 5px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 15px;
    color: #8c939d;
    /* width: 178px; */
    /* height: 178px; */
    line-height: 50px;
    text-align: center;
}
/* .avatar {
    width: 100%;
    height: 100%;
    display: block;
} */
</style>

