<template>
    <div id="pay">
        <my-header></my-header>
        <div class="pay">
            <div class="pay-head">
                <p class="head-title">确认信息</p>
                <button class="head-btn" @click="changeApply" v-if="from == 1&&this.is_detail == 2">编辑</button>
            </div>
            <div class="pay-table">
                <p class="pay-title">报名费<span class="pay-total">报名费{{applyMoney}}元</span></p>

                <div class="apply">
                    <el-table border :data="tableData" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}">
                        <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                        <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                        <el-table-column label="性别" align="center" width="55">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sport.sex==1">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                        <el-table-column prop="sport.team_name" label="参赛机构" align="center"></el-table-column>
                        <el-table-column label="个人项目" align="center">
                            <template slot-scope="scope">
                                <div v-for="(item,index) in scope.row.sign" :key="index">
                                    <span v-if="item.group.rule_type == 'personal'">{{item.group.name}},{{item.groupChild.name}},{{item.groupLevel.level_name}}</span>
                                    <span v-if="item.group.rule_type == 'double'">{{item.group.name}},{{item.groupChild.name}},{{item.groupLevel.level_name}}{{item.group_index}}组</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="团体项目" align="center">
                            <template slot-scope="scope">
                                <div v-for="(item,index) in scope.row.sign" :key="index">
                                    <span v-if="item.group.rule_type == 'team'">{{item.group.name}},{{item.groupChild.name}}{{item.group_index}}组</span>
                                    <span v-if="item.group.rule_type == 'dance'">{{item.group.name}},{{item.groupChild.name}}{{item.group_index}}组</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p class="pay-footer">共报名人数：{{tableData.length}}人<span class="payadd">会员费：{{memberMoney}}元</span><span class="payadd">报名费：{{totalApplyMoney}}元</span><span class="payadd">合计：{{totalApplyMoney+memberMoney}}元</span></p>
                </div>
            </div>
            <div class="pay-table">
                <p class="pay-title">兼项费<span class="pay-total">兼项费{{andMoney}}元</span></p>
                <div class="apply">
                    <el-table border :data="tableData" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}">
                        <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                        <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                        <el-table-column label="性别" align="center" width="55">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sport.sex==1">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                        <el-table-column prop="sport.team_name" label="参赛机构" align="center"></el-table-column>
                        <el-table-column label="共报项目" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.sign.length}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="jx_num" label="产生兼项费" align="center"></el-table-column>
                    </el-table>
                    <p class="pay-footer">产生兼项费为：{{andNum}}次<span class="payadd">合计：{{totalAndMoney}}元</span></p>
                </div>
            </div>
            <div class="pay-table">
                <p class="pay-title">保险费<span class="pay-total">保险费{{safeMoney}}元</span></p>
                <div class="apply">
                    <el-table border :data="tableData" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}">
                        <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                        <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                        <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                        <el-table-column label="是否购买保险" align="center">
                            <template slot-scope="scope">
                                <div style="text-align: center">
                                    <el-checkbox :disabled='show' v-model="scope.row.isSafe" @change="setSafe(scope.row)"></el-checkbox>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p class="pay-footer">产生保险费为：{{safeNumber}}次<span class="payadd">合计：{{totalSafeMoney}}元</span></p>
                </div>
            </div>
            <div class="pay-table">
                <p class="pay-title">电子脚套费<span class="pay-total">电子脚套费{{footMoney}}元</span></p>
                <div class="apply">
                    <el-table border :data="tableData_foot" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}">
                        <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                        <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                        <el-table-column label="性别" align="center" width="55">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sport.sex==1">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                        <el-table-column prop="sport.team_name" label="参赛机构" align="center"></el-table-column>
                        <!-- <el-table-column label="是否提供脚套" align="center">
                            <template slot-scope="scope">
                                <div style="text-align: center">
                                    <el-checkbox :disabled='show' v-model="scope.row.isFoot" @change="setFoot1(scope.row)"></el-checkbox>
                                </div>
                            </template>
                        </el-table-column> -->
                        <el-table-column label="脚码数（cm）" align="center" width="180">
                            <template slot-scope="scope">
                                <div style="text-align: center">
                                    <el-input :disabled='show' v-model="scope.row.footsize" @blur="setFoot(scope.row)"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p class="pay-footer">电子脚套费为：{{footNumber}}人<span class="payadd">合计：{{totalFootMoney}}元</span></p>
                </div>
            </div>
            <div class="pay-table">
                <p class="pay-title">大项费<span class="pay-total">大项费{{bigMoney}}元</span></p>
                <div class="apply">
                    <el-table border :data="bigList" style="width: 100%" algin='left' :header-cell-style="{background:'#F8F9FA'}">
                        <el-table-column type="index" label="序号" align="center" width="55"></el-table-column>
                        <el-table-column prop="sport.name" label="姓名" align="center" width="180"></el-table-column>
                        <el-table-column label="性别" align="center" width="55">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sport.sex==1">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sport.card_number" label="证件号码" align="center"></el-table-column>
                        <el-table-column prop="sport.team_name" label="参赛机构" align="center"></el-table-column>
                        <el-table-column label="项目" align="center">
                            <template slot-scope="scope">
                                <div v-for="(item,index) in scope.row.sign" :key="index">
                                    <span v-if="item.group.is_big == 1">{{item.group.name}},{{item.groupChild.name}}{{item.group_index}}组</span>
                                </div>
                            </template>
                        </el-table-column>·
                    </el-table>
                    <p class="pay-footer">大项费为：{{bigList.length}}人<span class="payadd">合计：{{totalBigMoney}}元</span></p>
                </div>
            </div>
            <div class="btn">
                <p>报名费{{totalApplyMoney}}元</p>
                <p>会员费{{memberMoney}}元</p>
                <p>兼项费{{totalAndMoney}}元</p>
                <p>保险费{{totalSafeMoney}}元</p>
                <p>保证金{{assureMoney}}元</p>
                <p>电子脚套费{{totalFootMoney}}元</p>
                <p>大项费{{totalBigMoney}}元</p>
                <p>合计{{totalMoney}}元</p>
                <p v-if="spendMoney>0">已缴费用{{spendMoney}}元</p>
                <p v-if="diff_money>0">需补缴费用{{diff_money}}元</p>
                <p v-if="diff_money<0">应退费用{{diff_money*-1}}元</p>
                <p style="margin-bottom:50px"> </p>
                <button v-if="this.back==0" class="btn-up" @click="lastStep">上一步</button>
                <button v-if="this.back==0" class="btn-down" @click="netxStep">去支付</button>
                <button v-if="this.back==2&&this.is_detail == 1" class="btn-down" @click="goback">返回</button>
                <button v-if="this.back==2&&this.is_detail == 2" class="btn-down" @click="netxStep1">提交</button>
            </div>
        </div>
    </div>
</template>

<script>
import { axios } from '@/utils/request'
import header from '@/components/header.vue'
export default {
    name: "pay",
    data() {
        return {
            tableData: [],
            tableData_foot: [],
            applyMoney: '',
            applyNum: 2,
            totalApplyMoney: '',
            andMoney: '',
            andNum: 0,
            totalAndMoney: '',
            safeMoney: '',
            totalSafeMoney: '',
            footMoney: '',
            totalFootMoney: '',
            bigMoney: '',
            bigNum: 2,
            totalBigMoney: '',
            assureMoney: '',
            totalMoney: '',
            spendMoney: 0,
            needMoney: '',
            returnMoney: '',
            match_id: '',
            memberMoney: '',
            a: 1,
            diff_money: 0,
            safeNumber: 0,
            safeList: [],
            footNumber: 0,
            footList: [],
            bigList: [],
            show: false,
            is_detail: '',
            back: 0,
            from: 0,
        };
    },
    created() {
        const { id } = this.$route.query
        console.log(this.$route.query)
        if (this.$route.query.type) {
            if (this.$route.query.type == 0) {
                this.is_detail = 1
                this.show = true
            } else if (this.$route.query.type == 1) {
                this.is_detail = 2
            }
            this.from = 1
            this.back = 1
        }
        this.match_id = id
        this.getPayList()
    },
    components: {
        'my-header': header
    },
    methods: {
        changeApply() {
            this.$router.push({
                path: '/applylist',
                query: {
                    id: this.match_id,
                    type: 1
                }
            })
        },
        goback() {
            this.$router.push({
                path: '/user/order'
            })
        },
        getPayList() {
            axios.post('/pc/team/computePrice/' + this.match_id, {
                bx: '',
                foot: '',
                is_detail: this.is_detail
            }).then(res => {
                if (res.status != '200') {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
                this.tableData = res.data.project
                this.totalBigMoney = res.data.bigPirce
                this.totalApplyMoney = res.data.bmPrice
                this.safeMoney = res.data.save_price
                this.totalFootMoney = res.data.footPrice
                this.totalAndMoney = res.data.jxPirce
                this.totalMoney = res.data.total_money
                this.memberMoney = res.data.memberPrice
                this.diff_money = res.data.diff_money
                this.totalSafeMoney = res.data.bxPrice
                this.assureMoney = res.data.savePrice
                this.bigMoney = res.data.match.big_price
                this.footMoney = res.data.match.foot_price
                this.safeMoney = res.data.match.insurance_price
                this.andMoney = res.data.match.jx_price
                this.applyMoney = res.data.match.price
                this.spendMoney = res.data.real_money
                for (var a = 0; a < this.tableData.length; a++) {
                    if (this.tableData[a].is_big == 1) {
                        this.bigList.push(this.tableData[a])
                    }
                    this.andNum += this.tableData[a].jx_num
                }
                for (var a = 0; a < this.tableData.length; a++) {
                    for (var b = 0; b < this.tableData[a].sign.length; b++) {
                        if (this.tableData[a].sign[b].group.is_foot == 1) {
                            this.tableData_foot.push(this.tableData[a])
                        }
                    }
                }
                let foot = res.data.foot
                let bx = res.data.bx
                this.safeNumber = bx.length
                this.footNumber = foot.length
                for (var a = 0; a < foot.length; a++) {
                    for (var b = 0; b < this.tableData.length; b++) {
                        if (foot[a].sport_id == this.tableData[b].sport_id) {
                            this.$set(this.tableData[b], "footsize", foot[a].size)
                        }
                    }

                }
                for (var a = 0; a < bx.length; a++) {
                    for (var b = 0; b < this.tableData.length; b++) {
                        if (bx[a] == this.tableData[b].sport_id) {
                            // this.tableData[b].isSafe = true
                            this.$set(this.tableData[b], "isSafe", true)

                        }
                    }
                }
                this.addMoney()
            })
        },
        setSafe(row) {
            if (row.isSafe) {
                this.safeNumber = this.safeNumber + 1
                this.safeList.push(row.sport_id)
            } else {
                this.safeNumber = this.safeNumber - 1
                for (var a = 0; a < this.safeList.length; a++) {
                    if (row.sport_id == this.safeList[a]) {
                        this.safeList.splice(a, 1)
                    }
                }
            }
            this.totalSafeMoney = this.safeNumber * Number(this.safeMoney)
            this.addMoney()
        },
        setFoot(row) {
            for (var a = 0; a < this.footList.length; a++) {
                if (row.sport_id == this.footList[a].id) {
                    this.footList[a].size = row.footsize
                }
            }
        },
        // setFoot1(row) {
        //     this.$forceUpdate()
        //     if (row.isFoot) {
        //         this.footNumber = this.footNumber + 1
        //         this.footList.push({ id: row.sport_id })
        //         // row.footsize = 40
        //     } else {
        //         this.footNumber = this.footNumber - 1
        //         for (var a = 0; a < this.footList.length; a++) {
        //             if (row.sport_id == this.footList[a]) {
        //                 this.footList.splice(a, 1)
        //             }
        //         }
        //     }
        //     this.totalFootMoney = this.footNumber * Number(this.footMoney)
        //     this.addMoney()
        // },
        addMoney() {

            this.totalMoney = Number(this.memberMoney) + Number(this.totalApplyMoney) + Number(this.totalAndMoney) + Number(this.totalSafeMoney) + Number(this.totalFootMoney) + Number(this.totalBigMoney) + Number(this.assureMoney)
            if (this.spendMoney > 0) {
                this.diff_money = Number(this.totalMoney) - Number(this.spendMoney)
            }
            if (this.from == 1 && this.diff_money > 0) {
                this.back = 0
            } else if (this.from == 1 && this.diff_money <= 0) {
                this.back = 2
            }
        },
        lastStep() {
            this.$router.push({
                name: 'applylist',
                query: {
                    id: this.match_id,
                }
            })
        },
        netxStep1() {
            let foot = []
            let bx = []
            for (var a = 0; a < this.tableData_foot.length; a++) {
                if (!this.tableData_foot[a].footsize || this.tableData_foot[a].footsize == '') {
                    this.$message({
                        type: "error",
                        message: '请先填写脚码'
                    });
                    return
                } else {
                    foot.push({
                        sport_id: this.tableData[a].sport_id,
                        size: this.tableData[a].footsize
                    })
                }
            }
            for (var a = 0; a < this.tableData.length; a++) {
                if (this.tableData[a].isSafe) {
                    bx.push(this.tableData[a].sport_id)
                }

            }
            axios.post('/pc/team/makeOrder/' + this.match_id, {
                bx: bx.join(','),
                foot: JSON.stringify(foot)
            }).then(res => {
                if (res.status == 200) {
                    this.$router.push({
                        path: '/user/order'
                    })
                }
                else {
                    this.$message({
                        type: "error",
                        message: res.message
                    });
                }
            })
        },
        netxStep() {
            let foot = []
            let bx = []
            for (var a = 0; a < this.tableData_foot.length; a++) {
                if (!this.tableData_foot[a].footsize || this.tableData_foot[a].footsize == '') {
                    this.$message({
                        type: "error",
                        message: '请先填写脚码'
                    });
                    return
                } else {
                    foot.push({
                        sport_id: this.tableData[a].sport_id,
                        size: this.tableData[a].footsize
                    })
                }
            }
            for (var a = 0; a < this.tableData.length; a++) {
                if (this.tableData[a].isSafe) {
                    bx.push(this.tableData[a].sport_id)
                }

            }
            axios.post('/pc/team/makeOrder/' + this.match_id, {
                bx: bx.join(','),
                foot: JSON.stringify(foot)
            }).then(res => {
                if (res.status == 200) {
                    this.$router.push({
                        name: 'paydetail',
                        query: {
                            id: this.match_id,
                            order_number: res.data.orderno
                        }
                    })
                }
                else {
                    this.$message({
                        type: "error",
                        message: res.message
                    });
                }
            })
            // console.log(this.footList)
            // this.$router.push({
            //     path: '/haveleader'
            // })
        },
    }
};
</script>

<style scoped>
#pay {
    min-height: 1080px;
    width: 100%;
    text-align: center;
    background-color: #f9fafe;
}
.head-btn {
    position: absolute;
    right: 50px;
    top: 20px;
    width: 121px;
    height: 36px;
    border: 2px solid #ff8f3a;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #ff8f3a;
    background-color: white;
}
.pay {
    width: 90%;
    min-height: 1550px;
    background-color: white;
    margin-left: 74px;
    margin-top: 45px;
    padding-bottom: 30px;
    border-radius: 15px;
}
.pay-head {
    width: 100%;
    height: 80px;
    border-bottom: 1px #f1f1f1 solid;
    display: flex;
    position: relative;
}
.head-title {
    text-align: left;
    font-size: 20px;
    font-weight: 550;
    color: #000000;
    line-height: 80px;
    margin-left: 59px;
}
.pay-table {
    width: 100%;
    height: auto;
    background-color: white;
}
.pay-title {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 30px;
    margin-top: 67px;
    margin-left: 59px;
}
.pay-total {
    font-size: 16px;
    font-weight: normal;
    margin-left: 23px;
}
.pay-footer {
    font-size: 16px;
    line-height: 16px;
    text-align: right;
    margin-top: 26px;
}
.pay-footer span {
    font-size: 16px;
    line-height: 16px;
    margin-left: 44px;
}
.apply {
    width: 90%;
    margin: 29px 0 0 46px;
    border-radius: 5px;
    height: auto;
}
.pay-box {
    display: flex;
}
.btn {
    /* width: 100%; */
    height: 36px;
    /* background-color: #686868; */
    text-align: right;
    margin-top: 183px;
    padding-bottom: 50px;
}
.btn p {
    text-align: right;
    margin-right: 64px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 26px;
}
.btn button {
    width: 121px;
    height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ff8f3a;
}
.btn-up {
    background-color: white;
    color: #ff8f3a;
    margin-right: 22px;
}
.btn-down {
    background-color: #ff8f3a;
    color: white;
    margin-right: 64px;
}
</style>
